import { IHeaderProps } from './Header';
import { FlexBoxRow } from '../../../FlexBoxRow';
import { Divider, Typography, TypographyProps, useMediaQuery } from '@mui/material';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { GradeUtils } from 'src/utils/GradeUtils';
import { ScoreName } from 'src/enums/ScoreName';
import { LyraTheme as theme } from 'src/components/theme/lyra/Theme';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import Caret from 'src/assets/Caret.svg';

export const ScoreBubblePlacing = (score: number, isCompetitor = false): { width: string; justifyContent: string } => {
  const HalfTheBoxWidth = isCompetitor ? '155px' : '127px';
  switch (GradeUtils.getScoreNameFromNumber(score)) {
    case ScoreName.FOUNDATIONAL:
      return { width: 'calc(100%/3)', justifyContent: 'center' };
    case ScoreName.ADVANCED:
      return { width: `calc(250%/3 + ${HalfTheBoxWidth})`, justifyContent: 'flex-end' };
    default:
      return { width: '100%', justifyContent: 'center' };
  }
};

export const ScoreBox = styled(FlexBoxColumn).attrs<{ $score: number; $ismobile: boolean }>((props) => ({ ...props }))(
  ({ theme, $score, $ismobile }) => {
    const border = (() => {
      switch (GradeUtils.getScoreNameFromNumber($score)) {
        case ScoreName.FOUNDATIONAL:
          return `${theme.spacing(0.25)} solid ${theme.palette.score.foundational}`;
        case ScoreName.INTERMEDIATE:
          return `${theme.spacing(0.25)} solid ${theme.palette.score.intermediate}`;
        case ScoreName.ADVANCED:
          return `${theme.spacing(0.25)} solid ${theme.palette.score.advanced}`;
      }
    })();

    return {
      border,
      padding: $ismobile ? '5px 6px 7px 6px' : `7px 8px 9px 8px`,
      height: $ismobile ? theme.spacing(5) : theme.spacing(6),
      width: $ismobile ? theme.spacing(5) : theme.spacing(6),
      gap: theme.spacing(0.125),
      borderRadius: theme.spacing(1),
      background: 'white',
    };
  }
);

export const BubbleBox = styled(FlexBoxRow)(({ theme }) => ({
  position: 'relative',
  background: theme.palette.common.white,
  padding: theme.spacing(1.5),
  gap: theme.spacing(1.5),
  alignSelf: 'flex-start',
  boxShadow: `0px 4px 24px 0px rgba(21, 26, 41, 0.10)`,
  borderRadius: theme.spacing(1.5),
}));

export const ScoreDivider = styled(Divider)(({ theme }) => ({
  borderBottomWidth: theme.spacing(0.125),
  width: theme.spacing(5),
  borderBottomColor: theme.palette.grey['400'], // neutral grey
}));

// Body-X-Small
export const BodyXSmall = styled(Typography).attrs<TypographyProps>((props) => ({ variant: 'body2', ...props }))(
  () => ({
    fontSize: '12px',
    lineHeight: '16px',
  })
);

// Commonly used to highlight text, making it bigger, bolder than body1
export const GradeBubbleText = styled(Typography).attrs<TypographyProps>((props) => ({ variant: 'body1', ...props }))(
  ({ theme }) => ({
    fontSize: useMediaQuery(theme.breakpoints.down('sm')) ? '20px' : '24px',
    lineHeight: useMediaQuery(theme.breakpoints.down('sm')) ? '20px' : '24px',
  })
);

export const HeaderScoreBubble = ({ score, date }: Pick<IHeaderProps, 'score' | 'date'>) => {
  const { t } = useTranslation(['websiteGrader']);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <FlexBoxRow sx={{ alignSelf: isMobile ? 'center' : 'stretch', justifyContent: 'stretch' }}>
      <FlexBoxRow
        sx={{
          width: isMobile ? 'initial' : ScoreBubblePlacing(score).width,
          justifyContent: ScoreBubblePlacing(score).justifyContent,
        }}
      >
        <BubbleBox>
          <ScoreBox $score={score} $ismobile={isMobile}>
            <GradeBubbleText sx={{ fontWeight: 600 }}>{score}</GradeBubbleText>
            <ScoreDivider />
            <Typography variant="body1" sx={{ fontWeight: 600, lineHeight: '16px' }}>
              {100}
            </Typography>
          </ScoreBox>
          <FlexBoxColumn sx={{ gap: 0.5, alignItems: 'flex-start', zIndex: 10 }}>
            <div>
              <Typography variant="body2">Your website grade is:</Typography>
              <GradeBubbleText sx={{ fontWeight: 600 }}>{GradeUtils.getScoreNameFromNumber(score)}</GradeBubbleText>
            </div>
            <BodyXSmall>
              {t('score.header.asOf')}&nbsp;{new Date(date).toLocaleDateString(process.env.REACT_APP_LOCALE)}
            </BodyXSmall>
          </FlexBoxColumn>
          <img src={Caret} style={{ position: 'absolute', bottom: '-12px' }} alt="Score Pointer Down Icon" />
        </BubbleBox>
      </FlexBoxRow>
    </FlexBoxRow>
  );
};
