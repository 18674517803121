import { GradeProcessorStatus } from 'src/hooks/overall/useProcessGrade';
import { IWebsiteGrade } from 'src/pages/websiteGrader/Report';

const timer = (ms: number) => new Promise((res) => setTimeout(res, ms));
const DELAY = 1000;
const poll = async (url: string) => {
  let status, response, json;

  while (!status || (json && (json.status === GradeProcessorStatus.PENDING || json.status === 'running'))) {
    await timer(DELAY);
    response = { status } = await fetch(url);
    json = await response.json();
  }

  if (json.error) throw json;

  return json;
};

export const PollWebsiteGrade = async (uuid: string) => {
  const URL = `${process.env.REACT_APP_BACKEND_URL}/website/scores/${uuid}`;
  const response = await poll(URL);
  return response;
};

export const PollCompetitorGrade = async (
  uuid: string,
  competitorId: number
): Promise<Omit<IWebsiteGrade, 'competitors'> & { competitorId: number }> => {
  const URL = `${process.env.REACT_APP_BACKEND_URL}/website/scores/${uuid}/competitors/${competitorId}`;
  const response = await poll(URL);
  return response;
};
