import { BoxProps, Divider, Typography, useMediaQuery } from '@mui/material';
import { ScoreName } from 'src/enums/ScoreName';
import { GradeUtils } from 'src/utils/GradeUtils';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { OrionColors } from 'src/components/theme/orion/common/colors';
import { LineHeight } from '../theme/orion/overrides/LineHeight';
import { FontWeight } from '../theme/orion/overrides/FontWeight';

const COMMON_DENOMINATOR = 100;

export enum GradeSize {
  Large = 'lg',
  Medium = 'md',
  Small = 'sm',
}

const GetSizeInPixels = (size: GradeSize): string => {
  switch (size) {
    case GradeSize.Large:
      return OrionTheme.spacing(14.5);
    case GradeSize.Medium:
      return OrionTheme.spacing(12);
    case GradeSize.Small:
      return OrionTheme.spacing(8);
  }
};

const GetBorderThicknessInPixels = (size: GradeSize): string => {
  switch (size) {
    case GradeSize.Large:
      return OrionTheme.spacing(0.625);
    case GradeSize.Medium:
      return OrionTheme.spacing(0.5);
    case GradeSize.Small:
      return OrionTheme.spacing(0.375);
  }
};

const GetNumeratorFontSize = (size: GradeSize): string => {
  switch (size) {
    case GradeSize.Large:
      return '3.375rem';
    case GradeSize.Medium:
      return '2.5rem';
    case GradeSize.Small:
      return '2rem';
  }
};

const GetDenominatorFontSize = (size: GradeSize): string => {
  switch (size) {
    case GradeSize.Large:
      return '1.5625rem';
    case GradeSize.Medium:
      return '1.25rem';
    case GradeSize.Small:
      return '0.875rem';
  }
};

const GetDividerHeight = (size: GradeSize): string => {
  switch (size) {
    case GradeSize.Large:
      return OrionTheme.spacing(0.5);
    case GradeSize.Medium:
      return OrionTheme.spacing(0.375);
    case GradeSize.Small:
      return OrionTheme.spacing(0.25);
  }
};

const GetColor = (inverse: boolean, value: string | number | undefined): string => {
  if (value === '-') return OrionColors.gray.neutral;
  return inverse ? 'common.white' : 'common.black';
};

interface Props {
  scorename: ScoreName | undefined;
  scorevalue: number | string | undefined;
  size?: GradeSize;
  inverse?: boolean;
  expandBorder?: boolean;
}

export const ScoreBox = ({
  scorename,
  scorevalue,
  size = GradeSize.Medium,
  inverse = false,
  expandBorder = false,
}: Props): JSX.Element => {
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down('sm'));

  const sx: BoxProps['sx'] = {
    color: GetColor(inverse, scorevalue),
    fontWeight: FontWeight.SemiBold,
    lineHeight: LineHeight.TIGHT,
  };

  return (
    <FlexBoxColumn
      sx={{
        width: isMobile && expandBorder ? '100%' : GetSizeInPixels(size),
        height: GetSizeInPixels(size),
        borderWidth: GetBorderThicknessInPixels(size),
        borderStyle: 'solid',
        borderColor: scorevalue === '-' ? 'action.disabled' : GradeUtils.getScoreColor(scorename, OrionTheme),
        borderRadius: OrionTheme.spacing(0.375),
        gap: size === GradeSize.Small ? OrionTheme.spacing(0.5) : OrionTheme.spacing(0.75),
        flexShrink: 0,
        zIndex: 1,
      }}
    >
      <Typography sx={{ ...sx, fontSize: GetNumeratorFontSize(size) }}>{`${scorevalue}`}</Typography>
      <Divider
        sx={{
          borderColor: GetColor(inverse, scorevalue),
          borderBottomWidth: GetDividerHeight(size),
          width: '66%',
          maxWidth: '100px',
        }}
      />
      <Typography sx={{ ...sx, fontSize: GetDenominatorFontSize(size) }}>{COMMON_DENOMINATOR}</Typography>
    </FlexBoxColumn>
  );
};
