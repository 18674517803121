import { styled, useMediaQuery } from '@mui/material';
import { FlexBoxColumn } from '../FlexBoxColumn';
import { ReactNode } from 'react';

import Background from 'src/assets/adgenerator/background/desktop.svg';
import BackgroundMobile from 'src/assets/adgenerator/background/mobile.svg';

const BackgroundBox = styled(FlexBoxColumn)(({ theme }) => ({
  position: 'absolute',
  left: useMediaQuery(theme.breakpoints.down('sm')) ? 0 : '155.609px',
  top: useMediaQuery(theme.breakpoints.down('sm')) ? 0 : '-167px;',
  backgroundImage: useMediaQuery(theme.breakpoints.down('sm')) ? `url(${BackgroundMobile})` : `url(${Background})`,
  width: useMediaQuery(theme.breakpoints.down('sm')) ? '100%' : 'calc(100% - 155.609px)',
  height: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}));

export const AdGeneratorBackground = ({ children }: { children?: ReactNode }) => {
  return (
    <FlexBoxColumn sx={{ alignSelf: 'stretch', background: 'white' }}>
      <BackgroundBox />
      <FlexBoxColumn sx={{ alignSelf: 'stretch', zIndex: 2 }}>{children}</FlexBoxColumn>
    </FlexBoxColumn>
  );
};
