import styled from 'styled-components';
import { FlexBoxColumn } from '../FlexBoxColumn';
import { FlexBoxRow } from '../FlexBoxRow';
import { OrionColors } from 'src/components/theme/orion/common/colors';

export const BackgroundContainer = styled(FlexBoxColumn)`
  background: linear-gradient(
    101deg,
    ${OrionColors.common.black} 0%,
    #04009d 50%,
    ${({ theme }) => theme.palette.primary.main} 100%
  );
  overflow: hidden;
`;

export const SubFooterBox = styled(FlexBoxRow)`
  background-color: ${({ theme }) => theme.palette.common.black};
  color: ${OrionColors.cream.blocking};
`;

export const GraderFooterGradient = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(
    101deg,
    ${OrionColors.common.black} 0%,
    #04009d 50%,
    ${({ theme }) => theme.palette.primary.main} 100%
  );
`;

export const KeywordsBackgroundContainer = styled(FlexBoxColumn)`
  background: linear-gradient(
    86deg,
    ${({ theme }) => theme.palette.primary.main} 0%,
    #04009d 33%,
    ${OrionColors.common.black} 100%
  );
  ${({ theme }) => theme.breakpoints.down('sm')} {
    background: linear-gradient(
      211deg,
      ${({ theme }) => theme.palette.primary.main} 0%,
      #04009d 50%,
      ${OrionColors.common.black} 100%
    );
  }
  overflow: hidden;
`;
