import { alpha, Typography, useMediaQuery } from '@mui/material';
import { FlexBoxColumn } from '../FlexBoxColumn';
import { LyraTheme as theme } from 'src/components/theme/lyra/Theme';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { ResponsiveRow } from '../ResponsiveRow';

interface IErrorProps {
  heading?: string;
  text?: string;
}

export const ErrorFlexBoxColumn = ({ heading, text }: IErrorProps) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <FlexBoxColumn
      sx={{
        gap: 2,
        alignSelf: 'stretch',
        background: `linear-gradient(
          0deg,
          ${alpha(theme.palette.primary.light, 0.4)} 0%,
          ${alpha(theme.palette.primary.light, 0.4)} 100%
        ),
        ${theme.palette.common.white}`,
        padding: isMobile ? theme.spacing(3) : '56px 80px',
        borderRadius: '30px',
      }}
    >
      <ResponsiveRow>
        <WarningAmberIcon sx={{ color: 'error.main', height: '36px', width: '36px', pb: isMobile ? 2 : 0 }} />
        <Typography variant="h3" textAlign={'center'} sx={{ display: 'inline', pl: isMobile ? 0 : 1 }}>
          {heading ?? "Oops, this page doesn't exist."}
        </Typography>
      </ResponsiveRow>
      <Typography variant="body1" sx={{ fontSize: 18, lineHeight: '24px', textAlign: 'center' }}>
        {text ?? "The link you followed may be broken, or the page doesn't exist."}
      </Typography>
    </FlexBoxColumn>
  );
};
