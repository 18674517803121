import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ScoreName } from 'src/enums/ScoreName';
import { GradeUtils } from 'src/utils/GradeUtils';
import { withSymbol } from '../utils';
import { ServiceType } from 'src/enums/ServiceType';
import { OrionColors } from 'src/components/theme/orion/common/colors';
import { FontWeight } from 'src/components/theme/orion/overrides/FontWeight';

type Props = {
  rowTitle: string;
  scorename: ScoreName;
  you?: number;
  competitor?: number;
};

export const BenchmarkTable = ({ rowTitle, scorename, you, competitor }: Props): JSX.Element => {
  const { t } = useTranslation([ServiceType.FACEBOOK]);

  return (
    <Table>
      <TableHead>
        <TableRow sx={{ borderBottom: `1px solid ${OrionColors.gray.neutral}` }}>
          <TableCell size="small" align="right" colSpan={2}>
            {t('grade.campaignGoals.table.benchmark.you')}
          </TableCell>
          <TableCell size="small" align="right">
            {t('grade.campaignGoals.table.benchmark.competitorAverage')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow sx={{ borderBottom: `1px solid ${OrionColors.gray.neutral}` }}>
          <TableCell align="left" sx={{ width: '60%' }}>
            <Typography variant="body1" textAlign="left">
              {rowTitle}
            </Typography>
          </TableCell>
          <TableCell align="right" sx={{ backgroundColor: GradeUtils.getScoreColorLight(scorename), width: '20%' }}>
            {withSymbol(you, '%')}
          </TableCell>
          <TableCell align="right">{withSymbol(competitor, '%')}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export const BenchmarkTableMobile = ({ rowTitle, scorename, you, competitor }: Props): JSX.Element => {
  const { t } = useTranslation([ServiceType.FACEBOOK]);
  const bgColor = GradeUtils.getScoreColorLight(scorename as ScoreName);

  return (
    <Table sx={{ tableLayout: 'fixed' }}>
      <tbody>
        <TableRow sx={{ borderBottom: `1px solid ${OrionColors.gray.neutral}` }}>
          <TableCell colSpan={2} padding="none" sx={{ px: 1, py: 2 }}>
            <Typography variant="body1">{rowTitle}</Typography>
          </TableCell>
        </TableRow>
        <TableRow sx={{ borderBottom: `1px solid ${OrionColors.gray.neutral}` }}>
          <TableCell size="small" sx={{ p: 1, fontWeight: FontWeight.SemiBold }}>
            {t('grade.you')}
          </TableCell>
          <TableCell sx={{ backgroundColor: bgColor, p: 1 }} align="right">
            {you ?? '-'}
          </TableCell>
        </TableRow>
        <TableRow sx={{ borderBottom: `1px solid ${OrionColors.gray.neutral}` }}>
          <TableCell size="small" sx={{ p: 1, fontWeight: FontWeight.SemiBold }}>
            {t('grade.competitorAvg')}
          </TableCell>
          <TableCell align="right" sx={{ p: 1 }}>
            {competitor ?? '-'}
          </TableCell>
        </TableRow>
      </tbody>
    </Table>
  );
};
