import { useEffect, useState } from 'react';
import { IWebsiteGrade, WebsiteScoreSubCategory } from './Report';
import { useWebsiteGraderContext } from 'src/components/providers/websiteGrader';
import { PollCompetitorGrade } from 'src/components/common/websiteGrader/PollWebsiteGrade';

// this hooks is used for tracking competitor-score esp. in case if the user refresh
// the page while the competitor score is still being processed
export const useReport = ({ grade }: { grade: IWebsiteGrade }) => {
  // processing value is for tracking processing of competitor-score
  // competitor-score inside the context is set only once processing is complete
  const { competitorScore, setCompetitorScore, processing, setProcessing } = useWebsiteGraderContext();
  const [selectedTab, setSelectedTab] = useState<WebsiteScoreSubCategory>(WebsiteScoreSubCategory.WEBSITE_BUILD);
  const [error, setError] = useState<Error | undefined>(undefined);

  const gradeCompetitorScore =
    Array.isArray(grade.competitors.competitors) &&
    grade.competitors.competitors.length > 0 &&
    grade.competitors.competitors[0]
      ? grade.competitors.competitors[0]
      : undefined;

  useEffect(() => {
    // if grade.competitors.competitors[0].status is still 'running'
    // means the competitor-score is still in processing
    if (gradeCompetitorScore) {
      // competitor score is available
      if (gradeCompetitorScore.status === 'complete') setCompetitorScore(gradeCompetitorScore);
      // competitor score isn't available yet & is still in processing
      else if (gradeCompetitorScore.status === 'running') {
        // poll running competitor score, once it's completed processing
        // update the competitor score & clear running compeitor score
        (async () => {
          let completedCompetitorScore;
          setProcessing(true);
          try {
            completedCompetitorScore = await PollCompetitorGrade(grade.uuid, gradeCompetitorScore.competitorId);
          } catch (error) {
            setError(error as Error);
          }
          // update state
          setCompetitorScore(completedCompetitorScore);
          setProcessing(false);
        })();
      }
    }
  }, [gradeCompetitorScore]);

  return { competitorScore, selectedTab, setSelectedTab, processing, error };
};
