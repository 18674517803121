import { Button, Divider, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ContentCardForErrors } from 'src/components/common/ContentCard';
import { StyledButton } from 'src/components/common/styled/StyledButton';
import { FacebookAdsPage } from 'src/components/facebook/page/Page';
import { GridType } from 'src/enums/GridType';
import { ServiceType } from 'src/enums/ServiceType';
import { OrionColors } from 'src/components/theme/orion/common/colors';
import { Email } from 'src/components/common/metrics/Email';
import ErrorFooter from 'src/components/facebook/errors/ErrorFooter';
import styled from 'styled-components';

const ExternalLink = styled(StyledButton)<{ target?: string }>`
  color: ${({ theme }) => theme.palette.common.white};
  text-decoration: none;
  text-align: center;
`;

ExternalLink.defaultProps = {
  target: '_blank',
};

export const FacebookAdsNoSpend = (): JSX.Element => {
  const { t } = useTranslation([ServiceType.FACEBOOK]);

  return (
    <FacebookAdsPage gridType={GridType.FORM} additionalFooter={<ErrorFooter />}>
      <ContentCardForErrors title={t('error.title_nospend')} body={t('error.body_nospend')}>
        <Button
          variant="contained"
          color="secondary"
          size="medium"
          LinkComponent={Link}
          href="https://www.wordstream.com/learn"
          target="_blank"
          style={{ textDecoration: 'none' }}
        >
          {t('error.button_nospend')}
        </Button>
        <Divider sx={{ width: '100%', marginY: 5, borderColor: OrionColors.gray.legacyTint }} />
        <Email />
      </ContentCardForErrors>
    </FacebookAdsPage>
  );
};
