import { Accordion, AccordionDetails, AccordionProps, AccordionSummary, Theme, Typography } from '@mui/material';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { FlexBoxRow } from 'src/components/common/FlexBoxRow';
import {
  DigitalAdvertisingAttribute,
  OptimizationLevel,
  SocialAndLocalPresenceAttribute,
  WebsiteBuildAttribute,
} from 'src/pages/websiteGrader/Report';
import { useTranslation } from 'react-i18next';
import { ResponsiveRow } from 'src/components/common/ResponsiveRow';

import QuestonBoxFoundation from 'src/assets/websitegrader/result/QuestonBoxFoundation.svg';
import QuestonBoxIntermediate from 'src/assets/websitegrader/result/QuestonBoxIntermediate.svg';
import QuestionBoxAdvanced from 'src/assets/websitegrader/result/QuestionBoxAdvanced.svg';

import FixFoundation from 'src/assets/websitegrader/result/FixFoundation.svg';
import FixIntermediate from 'src/assets/websitegrader/result/FixIntermediate.svg';
import Checkmark from 'src/assets/websitegrader/result/checkmark.svg';

import ArrowDown from 'src/assets/ArrowDown.svg';

import styled from 'styled-components';

interface ICustomAccordionProps extends AccordionProps {
  optimizationlevel: OptimizationLevel;
}

const CustomAccordion = styled(Accordion).attrs<ICustomAccordionProps>((props) => ({
  elevation: 0,
  disableGutters: true,
  sx: { ':before': { display: 'none' } },
  ...props,
}))(({ optimizationlevel, theme }) => ({
  borderLeftWidth: theme.spacing(1),
  borderLeftColor:
    optimizationlevel === OptimizationLevel.LOW
      ? (theme as Theme).palette.score?.foundational
      : optimizationlevel === 'medium'
      ? (theme as Theme).palette.score?.intermediate
      : (theme as Theme).palette.score?.advanced,
}));

const WhyThisMattersIcon = ({ optimizationlevel }: { optimizationlevel: OptimizationLevel }) => {
  switch (optimizationlevel) {
    case OptimizationLevel.LOW:
      return <img src={QuestonBoxFoundation} alt="Queston-Box-Icon-Red" />;
    case OptimizationLevel.MEDIUM:
      return <img src={QuestonBoxIntermediate} alt="Queston-Box-Icon-Yellow" />;
    case OptimizationLevel.HIGH:
      return <img src={QuestionBoxAdvanced} alt="Queston-Box-Icon-Green" />;
  }
  return <></>;
};

const HowToFixThis = ({ optimizationlevel }: { optimizationlevel: OptimizationLevel }) => {
  // Or KeepItUp
  switch (optimizationlevel) {
    case OptimizationLevel.LOW:
      return <img src={FixFoundation} alt="Wrench-Icon-Red" />;
    case OptimizationLevel.MEDIUM:
      return <img src={FixIntermediate} alt="Wrench-Icon-Yellow" />;
    case OptimizationLevel.HIGH:
      return <img src={Checkmark} alt="Check-Icon-Green" />;
  }
  return <></>;
};

const NormalFontWeightH5 = styled(Typography).attrs((props) => ({ variant: 'h5', ...props }))(() => ({
  fontWeight: 400,
}));

interface IAccordionCardProps {
  sectionattrname: WebsiteBuildAttribute | SocialAndLocalPresenceAttribute | DigitalAdvertisingAttribute;
  optimizationLevel: OptimizationLevel;
}

export const AccordionCard = ({ sectionattrname, optimizationLevel }: IAccordionCardProps) => {
  const { t } = useTranslation(['websiteGrader']);
  return (
    <CustomAccordion optimizationlevel={optimizationLevel}>
      <AccordionSummary expandIcon={<img src={ArrowDown} />} aria-controls="panel1-content" id="panel1-header">
        <Typography variant="body2">{t(`score.actions.${sectionattrname}.summary.title`)}</Typography>
        <NormalFontWeightH5>
          {t(`score.actions.${sectionattrname}.summary.desc_${optimizationLevel}`)}
        </NormalFontWeightH5>
      </AccordionSummary>
      <AccordionDetails>
        <ResponsiveRow sx={{ alignItems: 'flex-start', gap: 2 }}>
          <FlexBoxRow sx={{ gap: 1, alignItems: 'flex-start', flex: '1 0 0' }}>
            {/* Why this matters */}
            <div style={{ padding: '6px 0px 4px 0px' }}>
              <WhyThisMattersIcon optimizationlevel={optimizationLevel} />
            </div>
            <FlexBoxColumn sx={{ alignItems: 'flex-start', gap: 1 }}>
              <Typography variant="h5">{t('score.actions.whyThisMatters')}</Typography>
              <Typography variant="body1">{t(`score.actions.${sectionattrname}.details.explanation`)}</Typography>
            </FlexBoxColumn>
          </FlexBoxRow>
          <FlexBoxRow sx={{ gap: 1, alignItems: 'flex-start', flex: '1 0 0' }}>
            {/* Why this matters */}
            <div style={{ padding: '6px 0px 4px 0px' }}>
              <HowToFixThis optimizationlevel={optimizationLevel} />
            </div>
            <FlexBoxColumn sx={{ alignItems: 'flex-start', gap: 1 }}>
              <Typography variant="h5">
                {optimizationLevel === 'low'
                  ? t('score.actions.howToFixThis')
                  : optimizationLevel === 'medium'
                  ? t('score.actions.howToImproveThis')
                  : t('score.actions.keepItUp')}
              </Typography>
              <Typography variant="body1">
                {optimizationLevel === 'high'
                  ? t(`score.actions.${sectionattrname}.details.recommendation_pass`)
                  : t(`score.actions.${sectionattrname}.details.recommendation_fail`)}
              </Typography>
            </FlexBoxColumn>
          </FlexBoxRow>
        </ResponsiveRow>
      </AccordionDetails>
    </CustomAccordion>
  );
};
