import { createContext, Dispatch, SetStateAction, useMemo, useState } from 'react';
import { KeywordResponse } from 'src/types/keywords/KeywordResponse';
import { RequestStatus } from 'src/types/common/RequestStatus';
import { useSearchParams } from 'react-router-dom';
import { ILocation } from 'src/types/common/Location';
import { IKeywordForm } from '../keywords/form/useKeywordForm';

export const ALL_INDUSTRIES_CATEGORY = {
  id: '',
  name: 'All Industries',
};

const UNITED_STATES_LOCATION = {
  name: 'United States',
  canonicalName: 'United States',
  criteriaId: 2840,
};

const defaultSetGeoFlow: Dispatch<SetStateAction<string>> = () => {
  /* noop */
};

export const KeywordsContext = createContext<{
  keywords: KeywordResponse | null;
  setKeywords: React.Dispatch<React.SetStateAction<KeywordResponse | null>>;
  status: RequestStatus;
  setStatus: React.Dispatch<React.SetStateAction<RequestStatus>>;
  keywordFormState: IKeywordForm;
  setKeywordFormState: React.Dispatch<React.SetStateAction<IKeywordForm>>;
  geoFlow: string;
  setGeoFlow: React.Dispatch<React.SetStateAction<string>>;
}>({
  keywords: {} as KeywordResponse,
  setKeywords: () => null,
  status: {} as RequestStatus,
  setStatus: () => null,
  keywordFormState: {} as IKeywordForm,
  setKeywordFormState: () => null,
  geoFlow: '1',
  setGeoFlow: defaultSetGeoFlow,
});

type Props = {
  children: React.ReactNode;
};

export const KeywordsProvider = ({ children }: Props): JSX.Element => {
  const [searchParams] = useSearchParams();
  const [geoFlow, setGeoFlow] = useState<string>(searchParams.get('geoflow') || '1');
  const [keywords, setKeywords] = useState<KeywordResponse | null>(null);
  const [status, setStatus] = useState<RequestStatus>({} as RequestStatus);
  const [keywordFormState, setKeywordFormState] = useState({
    websiteURLOrKeyword: searchParams.get('website') || 'www.wordstream.com',
    industry: ALL_INDUSTRIES_CATEGORY.id,
    location: UNITED_STATES_LOCATION as ILocation,
  });

  return (
    <KeywordsContext.Provider
      value={useMemo(
        () => ({
          keywords,
          setKeywords,
          status,
          setStatus,
          keywordFormState,
          setKeywordFormState,
          geoFlow,
          setGeoFlow,
        }),
        [keywords, status, keywordFormState, geoFlow]
      )}
    >
      {children}
    </KeywordsContext.Provider>
  );
};
