export enum ScoreName {
  FOUNDATIONAL = 'Foundational',
  INTERMEDIATE = 'Intermediate',
  ADVANCED = 'Advanced',
  NO_SCORE = 'No_Score',
}

export enum ScoreRangeMax {
  FOUNDATIONAL = 59,
  INTERMEDIATE = 79,
  ADVANCED = 100,
}
