import { TableCell, TableRow } from '@mui/material';
import { OrionColors } from 'src/components/theme/orion/common/colors';
import { ScoreName } from 'src/enums/ScoreName';
import { GradeUtils } from 'src/utils/GradeUtils';

type Props = {
  text: string;
  you: number | string | undefined;
  competitors: number | string | undefined;
  scorename: ScoreName;
  withIcon?: boolean;
};

export const ATTableRow = ({ text, you, competitors, scorename }: Props): JSX.Element => {
  const bgColor = GradeUtils.getScoreColorLight(scorename);

  return (
    <TableRow sx={{ borderBottom: `1px solid ${OrionColors.gray.neutral}` }}>
      <TableCell>{text}</TableCell>
      <TableCell sx={{ backgroundColor: bgColor }} align="right">
        {you ?? '-'}
      </TableCell>
      <TableCell align="right">{competitors ?? '-'}</TableCell>
    </TableRow>
  );
};
