import { Card, useMediaQuery, Typography, alpha } from '@mui/material';
import { FlexBoxColumn } from '../FlexBoxColumn';
import { LyraTheme as theme } from 'src/components/theme/lyra/Theme';
import { useAdGeneratorContext } from 'src/components/providers/adGenerator';
import { AdGeneratorStep } from './Stepper';
import { useWebsiteGraderContext } from 'src/components/providers/websiteGrader';

import styled from 'styled-components';
import AiSparkle from 'src/assets/adgenerator/aisparkle.svg';

const NavBarHeightWithBorder = '88px'; // same across all screens
export const NavBarHeightWithNoBorder = '86px'; // same across all screens
const FooterHeight = '100px'; // when copyright text + policy links & social media logos placed next to each other in same row
const FooterHeightLinks3ColumnsMedium = '124px'; // when copyright text, links & social media logos all stacked on each other for medium screens
const FooterHeightLinks3ColumnsXS = '182px'; // when copyright text, links & social media logos all stacked on each other for small screens
const SubFooterHeight = '130px'; // when logo is placed next to the text, text is 2 lines
const SubFooterHeight2Columns = '190px'; // when logo is stacked upon the text, text is 2 lines
const SubFooterHeight2ColumnsText3Lines = '215px'; // when logo is stacked upon the text, text is 3 lines
const PaddingY = '192px'; // 96 + 96

export const GetLayoutWidth = (isNonResultPage: boolean, isMobile: boolean): string => {
  if (isMobile) return 'calc(100vw - 80px)';
  if (isNonResultPage) return 'calc(100vw - 240px)';
  else return 'calc(100vw - 140px)';
};

const GetLayoutPadding = (isNonResultPage: boolean, isMobile: boolean): string => {
  switch (isNonResultPage) {
    case true:
      switch (isMobile) {
        case true:
          return `${theme.spacing(5)} ${theme.spacing(5)}`;
        case false:
          return `${theme.spacing(12)} ${theme.spacing(15)}`;
      }
    /* falls through */
    case false:
      switch (isMobile) {
        case true:
          return `${theme.spacing(5)} ${theme.spacing(0)}`;
        case false:
          return `${theme.spacing(12)} ${theme.spacing(0)}`;
      }
  }
};

export const GetLayoutMaxWidth = (isNonResultPage: boolean, isMobile: boolean): string => {
  if (isNonResultPage) return 'calc(636px + 160px)';
  if (isMobile) return 'calc(100% - 80px)';
  else return 'calc(100% - 144px)';
};

export const GetLayoutSubFooterHeight = (isXLarge: boolean, isLarge: boolean, isMobile: boolean): string => {
  switch (true) {
    case isXLarge:
      return SubFooterHeight;
    case isLarge:
      return SubFooterHeight2Columns;
    case isMobile:
      return SubFooterHeight2ColumnsText3Lines;
    default:
      return SubFooterHeight2ColumnsText3Lines;
  }
};

export const GetLayoutFooterHeight = (isXLarge: boolean, isLarge: boolean, isMobile: boolean): string => {
  switch (true) {
    case isXLarge:
    case isLarge:
      return FooterHeight;
    case isMobile:
      return FooterHeightLinks3ColumnsXS;
    default:
      return FooterHeightLinks3ColumnsMedium;
  }
};

interface ILayoutAttrs {
  // screen variations
  $isMobile: boolean;
  $isLarge: boolean;
  $isXLarge: boolean;
  $isNonResultPage: boolean;
  $aiTool: boolean;
}

const FlexLayout = styled(FlexBoxColumn).attrs<ILayoutAttrs>(
  ({ $isMobile, $isLarge, $isXLarge, $isNonResultPage, $aiTool }: ILayoutAttrs) => ({
    $isMobile,
    $isLarge,
    $isXLarge,
    $isNonResultPage,
    $aiTool,
  })
)(({ $isMobile, $isLarge, $isXLarge, $isNonResultPage, $aiTool }) => ({
  width: GetLayoutWidth($isNonResultPage, $isMobile),
  maxWidth: GetLayoutMaxWidth($isNonResultPage, $isMobile),
  justifyContent: 'center',
  minHeight: `calc(
    100vh
    -
    ${$aiTool ? NavBarHeightWithBorder : NavBarHeightWithNoBorder}
    -
    ${GetLayoutSubFooterHeight($isXLarge, $isLarge, $isMobile)}
    -
    ${GetLayoutFooterHeight($isXLarge, $isLarge, $isMobile)}
    - ${PaddingY}
  )`,
  padding: GetLayoutPadding($isNonResultPage, $isMobile),
}));

const GetStyledCardPadding = (isNonResultPage: boolean, isMobile: boolean): string | number => {
  switch (isNonResultPage) {
    case true: {
      switch (isMobile) {
        case true:
          return theme.spacing(3);
        case false:
          return `${theme.spacing(7)} ${theme.spacing(10)}`;
      }
    }
    /* falls through */
    case false: {
      switch (isMobile) {
        case true:
        case false:
          return 0;
      }
    }
  }
};

const StyledCard = styled(Card).attrs<{ $isMobile: boolean; $isNonResultPage: boolean }>(
  ({ $isMobile, $isNonResultPage }: { $isMobile: boolean; $isNonResultPage: boolean }) => ({
    $isMobile,
    $isNonResultPage,
  })
)(({ theme, $isMobile, $isNonResultPage }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'stretch',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.grey[900],
  border: $isNonResultPage ? 'none' : `1px solid ${theme.palette.primary.light}`,
  borderRadius: $isNonResultPage ? theme.spacing(3.75) : theme.spacing(1),
  padding: GetStyledCardPadding($isNonResultPage, $isMobile),
  background: $isNonResultPage
    ? `
    linear-gradient(
      0deg,
      ${alpha(theme.palette.primary.light, 0.4)} 0%,
      ${alpha(theme.palette.primary.light, 0.4)} 100%
    ),
    ${theme.palette.common.white}
  `
    : 'none',
}));

export const Container = ({ $aiTool = true, children }: { $aiTool?: boolean; children?: JSX.Element }): JSX.Element => {
  const context = $aiTool ? useAdGeneratorContext() : useWebsiteGraderContext();
  const isXLarge = useMediaQuery(theme.breakpoints.up('lg'));
  const isLarge = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isNonResultPage =
    context.currentStep !== AdGeneratorStep.RESULT && context.currentStep !== AdGeneratorStep.FINETUNE_RESULT;

  return (
    <FlexLayout
      $isMobile={isMobile}
      $isLarge={isLarge}
      $isXLarge={isXLarge}
      $isNonResultPage={isNonResultPage}
      $aiTool={$aiTool}
      gap={7}
    >
      <FlexBoxColumn gap={3} sx={{ alignSelf: 'stretch' }}>
        {/*  AI Spakle is 36px high */}
        {$aiTool && isMobile ? <img src={AiSparkle} id="AI" alt="AISparkle" height={36} /> : <></>}
        {$aiTool ? (
          isMobile ? (
            <Typography variant="h1" textAlign={'center'} color={'grey.900'}>
              {'Google Ads AI Copy Tool'}
            </Typography>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
        <StyledCard elevation={0} $isMobile={isMobile} sx={{ p: 6 }} $isNonResultPage={isNonResultPage}>
          {children}
        </StyledCard>
      </FlexBoxColumn>
    </FlexLayout>
  );
};
