import { createContext, useState, useContext, useMemo } from 'react';
import { ServiceType } from 'src/enums/ServiceType';
import { IAccount } from 'src/types/common/Account';
import { IUserDetails } from 'src/types/common/UserDetails';
import { IFacebookContext } from 'src/types/facebook/FacebookContext';
import { DataServiceProvider } from './service';
import { IBusinessDetails } from 'src/types/common/BusinessDetails';
import { Step as FacebookStep } from 'src/enums/facebook/Step';

const FacebookContext = createContext<IFacebookContext>({} as IFacebookContext);

type Props = {
  children: JSX.Element | JSX.Element[] | React.ReactNode;
};

export const FacebookGraderContextProvider = ({ children }: Props): JSX.Element => {
  const [currentStep, setCurrentStep] = useState<FacebookStep>(FacebookStep.START);
  const [facebookAdsAccount, setFacebookAdsAccount] = useState<IAccount>({} as IAccount);
  const [userDetails, setUserDetails] = useState<IUserDetails>({} as IUserDetails);
  const [businessDetails, setBusinessDetails] = useState<IBusinessDetails>({} as IBusinessDetails);
  const [auth, setAuth] = useState<string | null>(null);
  const [isNewReport, setIsNewReport] = useState(false);

  const value: IFacebookContext = useMemo(
    () => ({
      currentStep,
      setCurrentStep,
      facebookAdsAccount,
      setFacebookAdsAccount,
      userDetails,
      setUserDetails,
      businessDetails,
      setBusinessDetails,
      auth,
      setAuth,
      isNewReport,
      setIsNewReport,
    }),
    [currentStep, facebookAdsAccount, userDetails, businessDetails, auth, isNewReport]
  );

  return <FacebookContext.Provider value={value}>{children}</FacebookContext.Provider>;
};

export const useFAContext = (): IFacebookContext => useContext(FacebookContext);

export const withFAProvider = (component: JSX.Element): JSX.Element => (
  <FacebookGraderContextProvider>
    <DataServiceProvider service={ServiceType.FACEBOOK}>{component}</DataServiceProvider>
  </FacebookGraderContextProvider>
);
