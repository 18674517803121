import {
  BrowserRouter,
  generatePath,
  Navigate,
  NavigateProps,
  Route,
  Routes,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import React, { FunctionComponent, lazy, Suspense } from 'react';
import { GoogleAdsHome } from './pages/googleAds/Home';
import { GoogleAdsAccountHome } from './pages/googleAds/AccountHome';
import { FacebookAdsAccountHome } from './pages/facebook/AccountHome';
import { GoogleAdsReport } from './pages/googleAds/Report';
import { SWRProvider } from './components/providers/swr';
import { GoogleAdsError } from './pages/googleAds/Error';
import { KeywordsHome } from './pages/keywords/Home';
import { GradeRefresh } from './pages/googleAds/GradeRefresh';
import { FacebookAdsHome } from './pages/facebook/Home';
import { withFAProvider } from './components/providers/facebookAds';
import { withGGProvider } from './components/providers/googleAds';
import { FacebookAdsReport } from './pages/facebook/Report';
import { GradeRefresh as FacebookGradeRefresh } from './pages/facebook/GradeRefresh';
import { FacebookAdsError } from './pages/facebook/Error';
import { KeywordsNoRoute } from './pages/keywords/NoRoute';
import { NotFound } from './pages/NotFound';
import { DisplayReport } from './pages/displayAds/Report';
import { ShoppingReport } from './pages/shopping/Report';
import { GoogleAdType } from './enums/GoogleAdType';
import { OverallReport } from './pages/overall/Report';
import { Unsubscribe } from './pages/Unsubscribe';
import { LoadingProvider } from './components/providers/loading';
import { Download } from './pages/keywords/Download';
import { OrionTheme } from './components/theme/orion/Theme';
import { alpha, ThemeProvider, Typography, useMediaQuery } from '@mui/material';
import { LyraTheme } from './components/theme/lyra/Theme';
import { withAdGeneratorProvider } from './components/providers/adGenerator';
import styled, { StyleSheetManager } from 'styled-components';
import { StyledLinearProgress } from './components/common/progress/LinearProgress';
import { FlexBoxColumn } from './components/common/FlexBoxColumn';
import {
  useWebsiteGraderContext,
  WebsiteGraderContextProvider,
  WebsiteGraderStep,
} from './components/providers/websiteGrader';
import { FlexBoxRow } from './components/common/FlexBoxRow';
import { ContainerForLoadingAndErrorComponents } from './components/common/websiteGrader/ContainerForLoadingAndErrorComponents';
import { PollWebsiteGrade } from './components/common/websiteGrader/PollWebsiteGrade';
import { useTranslation } from 'react-i18next';
import { WebsiteGraderReport } from 'src/pages/websiteGrader/Report';

import isPropValid from '@emotion/is-prop-valid';

// Below commented code is to trigger SuspenseFallbackLoading component locally
// // Simulate an asynchronous operation (e.g., data fetching)
// const fetchData = (): Promise<string> => {
//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve('Data loaded!');
//     }, 50000);
//   });
// };

// // Component that will be lazily loaded
// const DelayedWebsiteGrader = lazy(async () => {
//   // Await the asynchronous operation before resolving the component
//   await fetchData();
//   return import('src/pages/websiteGrader/WebsiteGrader');
// });
// Above commented code is to trigger SuspenseFallbackLoading component locally
const AdGenerator = lazy(() => import('src/pages/adGenerator/AdGenerator'));
const WebsiteGraderHome = lazy(() => import('src/pages/websiteGrader/WebsiteGrader'));
// can't pass props into lazy function hence has to create a wrapper function like below
// also can't use hooks inside the lazy call
const WebsiteGraderReportWithUUID = ({ UUID }: { UUID: string }) =>
  lazy(async () => {
    const grade = await PollWebsiteGrade(UUID);
    return {
      default: () => <WebsiteGraderReport grade={grade} />,
    };
  });
const ErrorBoundary = lazy(() => import('src/components/common/adGenerator/ErrorBoundary'));
// This implements the default behavior from styled-components v5
function shouldForwardProp(propName: string, target: unknown): boolean {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}

export const Providers = ({ children }: { children: JSX.Element }): JSX.Element => {
  return (
    <BrowserRouter
      future={{
        v7_relativeSplatPath: true,
        v7_startTransition: true,
      }}
    >
      <ThemeProvider theme={{ ...OrionTheme }}>
        <StyleSheetManager shouldForwardProp={shouldForwardProp}>
          <SWRProvider>
            <LoadingProvider>{children}</LoadingProvider>
          </SWRProvider>
        </StyleSheetManager>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export const BodyLarge = styled(Typography).attrs((props) => ({ variant: 'body1', ...props }))(({ theme }) => ({
  fontSize: useMediaQuery(theme.breakpoints.down('sm')) ? '18px' : '24px',
  lineHeight: useMediaQuery(theme.breakpoints.down('sm')) ? '24px' : '36px',
}));

// Don't use SuspenseFallbackLoader with Orion theme'd routing as this based on Lyra Theme
// currently Copy Tool & Website Garder has Lyra Theme'ng
const SuspenseFallbackLoading = ({ $aiTool }: { $aiTool: boolean }): JSX.Element => {
  const namespace = $aiTool ? 'adGenerator' : 'websiteGrader';
  const { currentStep } = useWebsiteGraderContext();
  const { t } = useTranslation([namespace]);
  const isLargerThanMobile = useMediaQuery(LyraTheme.breakpoints.up('sm'));
  return (
    <FlexBoxColumn
      sx={{
        minWidth: isLargerThanMobile ? '636px' : undefined,
        background: `
        linear-gradient(
          0deg,
          ${alpha(LyraTheme.palette.primary.light, 0.4)} 0%,
          ${alpha(LyraTheme.palette.primary.light, 0.4)} 100%
        ),
        ${LyraTheme.palette.common.white}
        `,
        borderRadius: '30px',
        padding: isLargerThanMobile ? '56px 80px' : '24px 24px',
      }}
    >
      <FlexBoxColumn sx={{ gap: 2 }}>
        <Typography variant="h3" textAlign={'center'}>
          {/*  user is transitioning from forms, so safe to assume that the grade is being just created */}
          {/*  hence would take atleast couple of mins to load the grade */}
          {currentStep === WebsiteGraderStep.LOADING_RESULTS ? t('loading.text_main') : t('loading.text_loading')}
        </Typography>
        <BodyLarge textAlign={'center'}>
          {/*  user is transitioning from forms, so safe to assume that the grade is being just created */}
          {/*  hence would take atleast couple of mins to load the grade */}
          {currentStep === WebsiteGraderStep.LOADING_RESULTS ? t('loading.text_time') : t('loading.text_loading_time')}
        </BodyLarge>
        <FlexBoxRow sx={{ alignSelf: 'stretch', pt: useMediaQuery(LyraTheme.breakpoints.down('sm')) ? 1 : 3 }}>
          <StyledLinearProgress throttle={true} />
        </FlexBoxRow>
      </FlexBoxColumn>
    </FlexBoxColumn>
  );
};

const Redirect: FunctionComponent<NavigateProps> = ({ to, replace }) => {
  const params = useParams();
  const [queryParams] = useSearchParams();
  const redirectWithParams = generatePath(to as string, params);

  return (
    <Navigate
      to={{
        pathname: redirectWithParams,
        search: Array.from(queryParams.values()).length > 0 ? `website=${queryParams.get('website')}` : '',
      }}
      replace={replace}
    />
  );
};

const WebsiteGraderReportHooksWrapper = () => {
  const { UUID } = useParams();
  const Component = WebsiteGraderReportWithUUID({ UUID: UUID as string });
  return (
    <ThemeProvider theme={{ ...LyraTheme }}>
      <Suspense
        fallback={
          <ContainerForLoadingAndErrorComponents $aiTool={false}>
            <SuspenseFallbackLoading $aiTool={false} />
          </ContainerForLoadingAndErrorComponents>
        }
      >
        <ErrorBoundary $aiTool={false}>{<Component />}</ErrorBoundary>
      </Suspense>
    </ThemeProvider>
  );
};

export const App = (): JSX.Element => {
  return (
    <Providers>
      {/*  As both '/website-grader' & '/website-grader/report/:UUID' routes share the same context have to declare it here */}
      {/*  can't  use like ai copy tool ex; withAdGeneratorProvider */}
      <WebsiteGraderContextProvider>
        <Routes>
          <Route path="/error" element={<NotFound />} />

          <Route path="/facebook-grader" element={withFAProvider(<FacebookAdsHome />)} />
          <Route path="/facebook-grader/error" element={withFAProvider(<FacebookAdsError />)} />
          <Route path="/facebook-grader/account" element={withFAProvider(<FacebookAdsAccountHome />)} />
          <Route path="/facebook-grader/report/:UUID/unsubscribe" element={withFAProvider(<Unsubscribe />)} />
          <Route path="/facebook-grader/report/:UUID/refresh" element={withFAProvider(<FacebookGradeRefresh />)} />
          <Route path="/facebook-grader/report/:UUID" element={withFAProvider(<FacebookAdsReport />)} />
          <Route path="/facebook-grader/*" element={<Redirect to="/facebook-grader/error?event=noRoute" />} />

          <Route path="/google-grader" element={withGGProvider(<GoogleAdsHome />)} />
          <Route path="/google-grader/error" element={withGGProvider(<GoogleAdsError />)} />
          <Route path="/google-grader/account" element={withGGProvider(<GoogleAdsAccountHome />)} />
          <Route path="/google-grader/report/:UUID/unsubscribe" element={withGGProvider(<Unsubscribe />)} />
          <Route path="/google-grader/report/:UUID/refresh" element={withGGProvider(<GradeRefresh />)} />
          <Route path="/google-grader/report/:UUID" element={withGGProvider(<OverallReport />, GoogleAdType.OVERALL)} />
          <Route
            path="/google-grader/search/:UUID"
            element={withGGProvider(<GoogleAdsReport />, GoogleAdType.SEARCH)}
          />
          <Route
            path="/google-grader/display/:UUID"
            element={withGGProvider(<DisplayReport />, GoogleAdType.DISPLAY)}
          />
          <Route
            path="/google-grader/shopping/:UUID"
            element={withGGProvider(<ShoppingReport />, GoogleAdType.SHOPPING)}
          />
          <Route path="/google-grader/*" element={<Redirect to="/google-grader/error?event=noRoute" />} />

          <Route path="/fkt" element={<KeywordsHome />} />
          <Route path="/fkt/noroute" element={<KeywordsNoRoute />} />
          <Route path="/fkt/download/:UUID" element={<Download />} />
          <Route path="/fkt/*" element={<Redirect to="/fkt/noroute" />} />
          <Route path="/keywords" element={<Redirect to="/fkt" replace={true} />} />
          <Route path="/keywords/download/:UUID" element={<Download />} />
          <Route path="/keywords/*" element={<Redirect to="/fkt/noroute" replace={true} />} />
          {/*  LyraTheme Starts */}
          {/*  Ad Generator Starts */}
          <Route
            path="/copy-tool"
            element={
              <ThemeProvider theme={{ ...LyraTheme }}>
                <SWRProvider>
                  <Suspense
                    fallback={
                      <ContainerForLoadingAndErrorComponents $aiTool={true}>
                        <SuspenseFallbackLoading $aiTool={true} />
                      </ContainerForLoadingAndErrorComponents>
                    }
                  >
                    <ErrorBoundary>{withAdGeneratorProvider(<AdGenerator />)}</ErrorBoundary>
                  </Suspense>
                </SWRProvider>
              </ThemeProvider>
            }
          />
          {/*  Ad Generator Ends  */}
          {/*  Website Grader Starts */}
          <Route
            path="/website-grader"
            element={
              <ThemeProvider theme={{ ...LyraTheme }}>
                <SWRProvider>
                  <Suspense
                    fallback={
                      <ContainerForLoadingAndErrorComponents $aiTool={false}>
                        {/* Don't show loading indicator here, hence empty element */}
                        <></>
                      </ContainerForLoadingAndErrorComponents>
                    }
                  >
                    <ErrorBoundary $aiTool={false}>{<WebsiteGraderHome />}</ErrorBoundary>
                  </Suspense>
                </SWRProvider>
              </ThemeProvider>
            }
          />
          <Route path="/website-grader/report/:UUID" element={<WebsiteGraderReportHooksWrapper />} />
          {/*  Website Grader Ends */}
          {/* LyraTheme Ends */}
          <Route path="*" element={<Redirect to="/error" replace={true} />} />
        </Routes>
      </WebsiteGraderContextProvider>
    </Providers>
  );
};
