import { FormControl, ListSubheader, MenuItem, paperClasses, Select, Theme, Typography } from '@mui/material';
import { BodyXSmall, GradeBubbleText, ScoreBox, ScoreDivider } from '../header/HeaderScoreBubble';
import { FlexBoxRow } from 'src/components/common/FlexBoxRow';
import { IWebsiteGrade, WebsiteScoreSubCategory } from 'src/pages/websiteGrader/Report';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { Close } from '@mui/icons-material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LyraTheme as theme } from 'src/components/theme/lyra/Theme';

import ArrowDown from 'src/assets/ArrowDown.svg';

// Lyra styling with few more extra tweak esp. with backdrop, marginThreshold, min-width, border-bottom-radius -> 0
const LyraInputSelectMenuProps = (theme: Theme) => ({
  elevation: 0,
  marginThreshold: 0,
  BackdropProps: {
    style: {
      opacity: 0.856,
      background: 'rgba(30, 33, 41, 0.85)',
    },
  },
  MenuListProps: {
    sx: {
      padding: 0,
    },
  },
  sx: {
    [`& .${paperClasses.elevation0}`]: {
      'box-shadow': '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      border: `${theme.spacing(0.125)} solid rgba(0, 0, 0, 0.15)`,
      borderRadius: theme.spacing(1.5),
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      minWidth: 'calc(100% - 2px)',
    },
    '& .MuiMenuItem-root': {
      padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
      whiteSpace: 'pre-wrap',
      '&:hover': {
        backgroundColor: 'rgba(0, 70, 254, 0.12)',
      },
    },
  },
});

interface IMetricSelector {
  grade: IWebsiteGrade;
  sections: WebsiteScoreSubCategory[];
  selectedsection: WebsiteScoreSubCategory;
  onChange: (section: WebsiteScoreSubCategory) => void;
}

export const MetricSelector = ({ grade, sections, selectedsection, onChange }: IMetricSelector) => {
  const { t } = useTranslation(['websiteGrader']);
  const [open, setOpen] = useState(false);

  return (
    <FlexBoxColumn
      sx={{
        gap: 1,
        alignSelf: 'stretch',
        py: 2,
        borderBottom: '1px solid  rgba(0, 0, 0, 0.15)',
        alignItems: 'stretch',
      }}
    >
      <FormControl>
        <Select
          variant="outlined"
          autoWidth={true}
          MenuProps={{
            ...LyraInputSelectMenuProps(theme),
            anchorEl: document.getElementById('website-grader-footer'),
          }}
          IconComponent={(props) => <img src={ArrowDown} {...props} alt="Arrow-Icon" />}
          open={open}
          onOpen={() => setOpen(true)}
          value={selectedsection}
          onChange={(event) => {
            onChange((event.target as HTMLInputElement).value as WebsiteScoreSubCategory);
            setOpen(false);
          }}
        >
          <ListSubheader sx={{ p: '8px 16px 8px 24px' }}>
            <FlexBoxRow sx={{ justifyContent: 'space-between' }}>
              <Typography variant="h5" sx={{ fontSize: '20px', lineHeight: '28px', fontWeight: 600, color: '#1E2129' }}>
                {'Grade Categories'}
              </Typography>
              <Close onClick={() => setOpen(false)} />
            </FlexBoxRow>
          </ListSubheader>

          {sections.map((section) => (
            <MenuItem value={section} key={section}>
              <FlexBoxRow sx={{ gap: 2, justifyContent: 'flex-start' }}>
                <ScoreBox $score={grade.subCategories[section].subScore} $ismobile={true}>
                  <GradeBubbleText sx={{ fontWeight: 600 }}>{grade.subCategories[section].subScore}</GradeBubbleText>
                  <ScoreDivider />
                  <Typography variant="body1" sx={{ fontWeight: 600, lineHeight: '16px' }}>
                    {100}
                  </Typography>
                </ScoreBox>
                <FlexBoxRow sx={{ justifyContent: 'flex-start', gap: 2 }}>
                  <FlexBoxRow style={{ flex: '1 0 0' }}>
                    <Typography variant="h4" sx={{ lineHeight: '24px', fontWeight: 400 }}>
                      {/* eslint-disable-next-line */}
                      <div dangerouslySetInnerHTML={{ __html: t(`score.header.${section}`) }} />
                    </Typography>
                  </FlexBoxRow>
                </FlexBoxRow>
              </FlexBoxRow>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <BodyXSmall textAlign={'center'}>{'13 Performance Metrics Analyzed'}</BodyXSmall>
    </FlexBoxColumn>
  );
};
