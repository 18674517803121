import { useTranslation } from 'react-i18next';
import { Divider, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { BoldTypography } from 'src/components/common/styled/BoldTypography';
import { AdFormatTableRow } from 'src/components/displayAds/grade/adFormats/styled/TableRow';
import { getScoreName } from 'src/components/facebook/grade/table/getScoreName';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { IDisplayAdTypeMetrics } from 'src/types/display/DisplayGrade';
import { DisplayAdSectionTitle } from 'src/enums/display/DisplayAds';
import { withSymbol } from '../../../facebook/grade/table/utils';
import { GoogleAdType } from 'src/enums/GoogleAdType';
import { MobileTableSection } from './styled/MobileTableSection';
import { OrionColors } from 'src/components/theme/orion/common/colors';

type Props = {
  data: IDisplayAdTypeMetrics | undefined;
};

export const AdMetric = ({ data }: Props): JSX.Element => {
  const { t } = useTranslation([GoogleAdType.DISPLAY]);

  return (
    <FlexBoxColumn sx={{ width: '100%' }}>
      {data?.adType && (
        <BoldTypography variant="h5" sx={{ alignSelf: 'flex-start' }}>
          {DisplayAdSectionTitle[`${data.adType}`]}
        </BoldTypography>
      )}
      <Table>
        <TableHead>
          <TableRow sx={{ borderBottom: `1px solid ${OrionColors.gray.neutral}` }}>
            <TableCell align="right" size="small" colSpan={2}>
              {t('grade.you')}
            </TableCell>
            <TableCell align="right" sx={{ whiteSpace: 'pre' }} size="small">
              {t('grade.competitorAvg')}
            </TableCell>
            <TableCell align="right" sx={{ whiteSpace: 'pre' }} size="small">
              {t('grade.clicks')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <AdFormatTableRow
            cellsWidth="20%"
            text={t('grade.ctr')}
            you={withSymbol(data?.ctr, '%', 'Not Used')}
            competitors={withSymbol(data?.competitorCtr, '%', '0')}
            clicks={data?.clicks.toLocaleString()}
            scorename={getScoreName({
              you: data?.ctr,
              benchmark: data?.competitorCtr,
              difference: data?.ctrPercentDiff,
              youZeroNotUsed: true,
            })}
          />
        </TableBody>
      </Table>
    </FlexBoxColumn>
  );
};

export const AdMetricMobile = ({ data }: Props): JSX.Element => {
  const titleKey = data?.adType ? data.adType : Object.keys(DisplayAdSectionTitle)[2];
  const { t } = useTranslation([GoogleAdType.DISPLAY]);
  const title = DisplayAdSectionTitle[titleKey as keyof typeof DisplayAdSectionTitle];

  return (
    <FlexBoxColumn sx={{ width: '100%', height: '100%' }}>
      <BoldTypography variant="h5" sx={{ alignSelf: 'flex-start' }}>
        {title}
      </BoldTypography>
      <Divider sx={{ width: '100%', borderColor: OrionColors.gray.neutral, marginY: 1 }} />
      <Table sx={{ tableLayout: 'fixed' }}>
        <TableBody>
          <MobileTableSection
            text={t('grade.ctr')}
            you={withSymbol(data?.ctr, '%', 'Not Used')}
            competitors={withSymbol(data?.competitorCtr, '%', '0')}
            clicks={data?.clicks.toLocaleString()}
            scorename={getScoreName({
              you: data?.ctr,
              benchmark: data?.competitorCtr,
              difference: data?.ctrPercentDiff,
              youZeroNotUsed: true,
            })}
          />
        </TableBody>
      </Table>
      <Divider sx={{ width: '100%', borderColor: OrionColors.gray.neutral, marginTop: 1 }} />
    </FlexBoxColumn>
  );
};
