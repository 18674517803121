import { useContext, useEffect, useState } from 'react';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';
import { postSubscribers } from 'src/utils/helpers';
import { ServiceContext } from 'src/components/providers/service';

type ReturnType = {
  subscribed: boolean;
  onToggle: () => void;
};

export const useMetricsSubscribed = (): ReturnType => {
  const { UUID } = useParams();
  const [subscribed, setSubscribed] = useState(false);
  const [manualToggle, setManualToggle] = useState(false);
  const [service] = useContext(ServiceContext);
  const { data } = useSWR(`${process.env.REACT_APP_BACKEND_URL}/${service}/${UUID}/subscribers`, { onError: () => null });

  const handleToggle = (newSubscribed: boolean) => {
    setSubscribed(newSubscribed);
    if (UUID) {
      postSubscribers(newSubscribed, service, UUID);
    }
  };

  const onToggle = () => {
    setManualToggle(true);
    handleToggle(!subscribed);
  };

  useEffect(() => {
    /*
     * Initially, manualToggle is set to false which will force an unsubscribe api call if subscribed is true
     * manualToggle set to true in all cases to prevent loop
    */
    if (data) {
      setSubscribed(data.subscribed);
      if (data.subscribed && !manualToggle) {
        setManualToggle(true);
        handleToggle(false);
      }
    }
  }, [data]);

  return { subscribed, onToggle };
};
