import React, { useContext, useEffect, useState } from 'react';
import { Button, Link, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { ShareReportModal } from 'src/components/common/grade/ShareReportModal';
import { usePageContent } from 'src/components/common/page/usePageContent';
import { GoogleAdType } from 'src/enums/GoogleAdType';
import { AlertSnackbar } from 'src/components/common/AlertSnackbar';
import { ServiceContext } from 'src/components/providers/service';
import { ServiceType } from 'src/enums/ServiceType';
import { capitalizeFirstLetter } from 'src/utils/helpers';
import { useParams } from 'react-router-dom';
import { ReactComponent as Chevron } from 'src/assets/Chevron.svg';
import { ResponsiveRow } from 'src/components/common/ResponsiveRow';
import { ShareFBReportModal } from 'src/components/facebook/ShareReportModal';

type HeaderActionsProps = {
  googleAdType: GoogleAdType;
};

export const HeaderActions = ({ googleAdType }: HeaderActionsProps): JSX.Element => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { UUID } = useParams();
  const [service] = useContext(ServiceContext);
  const { t } = useTranslation(['common']);
  const {
    openModal,
    handleOpenModal,
    handleCloseModal,
    handleEmailResponse,
    handleGoogleRefresh,
    handleFacebookRefresh,
    emailResponse,
  } = usePageContent();
  const showOverallButton = service !== ServiceType.FACEBOOK && googleAdType !== GoogleAdType.OVERALL;
  const [emailMessage, setEmailMessage] = useState<string | null>(emailResponse?.emailResponse ?? null);

  useEffect(() => {
    setEmailMessage(emailResponse?.emailResponse ?? null);
  }, [emailResponse]);

  const NoOfSections = (adType: GoogleAdType): number => {
    switch (adType) {
      case GoogleAdType.SEARCH:
        return 4;
      case GoogleAdType.DISPLAY:
      case GoogleAdType.SHOPPING:
        return 3;
      default:
        return service === ServiceType.FACEBOOK ? 4 : 7;
    }
  };

  return (
    <>
      <ResponsiveRow breakpoint={'sm'} gap={2} sx={{ alignItems: isMobile ? 'flex-start' : 'center' }}>
        <Tooltip
          enterTouchDelay={0}
          title={
            <Trans
              t={t}
              i18nKey={'howWeGetThisScoreToolTip'}
              values={{ service: capitalizeFirstLetter(service), quantity: NoOfSections(googleAdType) }}
            />
          }
          placement="bottom"
          arrow
        >
          <span>
            <Link
              href="#"
              variant="body1"
              sx={{
                color: 'common.white',
                borderBottom: `1px dotted ${theme.palette.background.default}`,
                textDecoration: 'none',
                ariaLabel: t('howWeGetThisScore'),
              }}
            >
              {t('howWeGetThisScore')}
            </Link>
          </span>
        </Tooltip>
        <Button
          color={'white'}
          variant="outlined"
          size={'medium'}
          fullWidth={isMobile}
          aria-label={t('checkNewScore')}
          onClick={service === ServiceType.FACEBOOK ? handleFacebookRefresh : handleGoogleRefresh}
          sx={{ whiteSpace: 'pre' }}
        >
          {t('checkNewScore')}
        </Button>
        {!showOverallButton && (
          <Button
            color={'white'}
            variant="outlined"
            size={'medium'}
            fullWidth={isMobile}
            aria-label={t('shareReport')}
            onClick={handleOpenModal}
            sx={{ whiteSpace: 'pre' }}
          >
            {t('shareReport')}
          </Button>
        )}
        {showOverallButton && (
          <Button
            variant="contained"
            color="white"
            size="medium"
            fullWidth={isMobile}
            href={`/google-grader/report/${UUID}`}
            sx={{ color: 'common.black', whiteSpace: 'pre' }}
            startIcon={<Chevron />}
          >
            {t('googleHeader.button_results')}
          </Button>
        )}
      </ResponsiveRow>
      {service === ServiceType.FACEBOOK ? (
        <ShareFBReportModal open={openModal} onClose={handleCloseModal} onResponse={handleEmailResponse} />
      ) : (
        <ShareReportModal open={openModal} onClose={handleCloseModal} onResponse={handleEmailResponse} />
      )}{' '}
      {emailMessage && <AlertSnackbar message={emailMessage} onClose={() => setEmailMessage(null)} />}
    </>
  );
};
