import { FormProvider, useForm } from 'react-hook-form';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { debounce, OutlinedInput, Typography, TypographyProps, useMediaQuery } from '@mui/material';
import { LyraTheme as theme } from 'src/components/theme/lyra/Theme';
import { ArrowForward } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { TFunction, useTranslation } from 'react-i18next';
import { useSubmitCompetitorForm } from './useSubmitCompetitorForm';
import { StyledLinearProgress } from 'src/components/common/progress/LinearProgress';
import { FlexBoxRow } from 'src/components/common/FlexBoxRow';

import styled from 'styled-components';
import * as Yup from 'yup';

const urlRegex = new RegExp(
  /[(http(s)?)://(www.)?a-zA-Z0-9@:%._\-+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi
);

const H4NormalText = styled(Typography).attrs<TypographyProps>((props) => ({ variant: 'h4', ...props }))(() => ({
  fontWeight: 400,
}));

const getValidationSchema = ({ t }: { t: TFunction }) =>
  Yup.object().shape({
    uuid: Yup.string(),
    website: Yup.string()
      .required(t('errors.required', { ns: 'common' }))
      .matches(urlRegex, t('errors.websiteNotValid', { ns: 'common' })),
  });

interface ICompetitorProps {
  uuid: string;
  $running: boolean;
}

export const Competitor = ({ uuid, $running }: ICompetitorProps) => {
  const { t } = useTranslation(['websiteGrader', 'common']);
  const isLarge = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const methods = useForm({ defaultValues: { website: '', uuid }, resolver: yupResolver(getValidationSchema({ t })) });
  const [endIconColor, setEndIconColor] = useState<string>('grey.100');
  const [cursor, setCursor] = useState<string>('pointer');
  const { disabled, processing, onSubmit } = useSubmitCompetitorForm(methods.getValues);
  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter' && (event.target as HTMLInputElement).value.trim()) {
      event.preventDefault();
      methods.setValue('website', (event.target as HTMLInputElement).value.trim());
      methods.clearErrors('website');
      methods.handleSubmit(onSubmit)();
    }
  };

  useEffect(() => {
    if (disabled) setEndIconColor('grey.100');
    else setEndIconColor('primary.main');
  }, [disabled]);

  return (
    <FlexBoxColumn alignSelf={'center'} sx={{ minWidth: isLarge ? '480px' : 'initial' }}>
      {processing || $running ? (
        <FlexBoxColumn
          sx={{
            background: theme.palette.common.white,
            p: isMobile ? theme.spacing(2) : theme.spacing(3),
            borderRadius: theme.spacing(1.5),
            gap: theme.spacing(1),
          }}
        >
          <Typography variant="h4" textAlign={'center'}>
            {t('loading.text_competitor')}
          </Typography>
          <Typography variant="body1">{t('loading.text_time')}</Typography>
          <FlexBoxRow sx={{ alignSelf: 'stretch', pt: isMobile ? theme.spacing(1) : theme.spacing(2) }}>
            <StyledLinearProgress throttle={true} />
          </FlexBoxRow>
        </FlexBoxColumn>
      ) : (
        <FormProvider {...methods}>
          <input style={{ display: 'none' }} name="uuid" defaultValue={uuid} />
          <FlexBoxColumn sx={{ alignSelf: 'stretch', gap: isMobile ? theme.spacing(2) : theme.spacing(3) }}>
            <H4NormalText sx={{ textAlign: isMobile ? 'center' : 'left' }}>
              {t('score.header.compareCompetitors')}
            </H4NormalText>
            <OutlinedInput
              name="website"
              size="small"
              sx={{
                '&.MuiOutlinedInput-root': {
                  borderRadius: theme.spacing(1.5),
                  background: 'white',
                },
                '.MuiOutlinedInput-notchedOutline': { borderColor: 'grey.900' },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'grey.900',
                },
              }}
              className={methods.formState.errors.website && 'Mui-error'}
              endAdornment={
                <ArrowForward
                  sx={{ color: endIconColor, cursor }}
                  onClick={function (event) {
                    event.preventDefault();
                    const inputElement: HTMLInputElement | null =
                      (event.target as HTMLElement).tagName === 'svg' // clicked on svg
                        ? (event.target as HTMLElement).previousSibling &&
                          ((event.target as HTMLElement).previousSibling as HTMLInputElement)
                        : (event.target as HTMLElement).parentElement && // clicked on svg -> path element
                          (((event.target as HTMLElement).parentElement as HTMLElement)
                            .previousSibling as HTMLInputElement);
                    if (inputElement && inputElement.value && inputElement.value.trim()) {
                      methods.setValue('website', inputElement.value.trim());
                      methods.handleSubmit(onSubmit)();
                    } else methods.handleSubmit(onSubmit)();
                  }}
                />
              }
              placeholder="Enter competitor's website URL…"
              fullWidth={true}
              disabled={disabled}
              onChange={debounce(function (event) {
                const value = (event.target.value ?? '').trim();
                if (value) {
                  setEndIconColor('primary.main');
                  setCursor('pointer');
                } else {
                  setEndIconColor('grey.100');
                  setCursor('none');
                }
              }, 500)}
              onKeyDown={onKeyDown}
            />
          </FlexBoxColumn>
        </FormProvider>
      )}
    </FlexBoxColumn>
  );
};
