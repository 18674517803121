import { Button, ButtonProps } from '@mui/material';
import { ReactNode } from 'react';
import { FieldValues, SubmitHandler, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ForwardArrow from 'src/assets/ForwardArrow.svg';

export const FormStepsNextButton: React.FC<{
  disabled?: boolean;
  onSubmit: SubmitHandler<FieldValues>;
  id: string;
  color?: 'primary' | 'secondary';
  endIcon?: ReactNode;
  sx?: ButtonProps['sx'];
  type?: 'button' | 'submit' | 'reset';
}> = ({ disabled = false, onSubmit, id, color, endIcon, sx, type = "submit" }) => {
  const { t } = useTranslation(['common']);
  const { handleSubmit } = useFormContext();

  return (
    <Button
      data-testid="nextButton"
      variant="contained"
      color={color ?? 'secondary'}
      size="medium"
      onClick={handleSubmit(onSubmit)}
      endIcon={endIcon ?? <img src={ForwardArrow} alt="Next" />}
      disabled={disabled}
      id={id}
      aria-label={t('next')}
      sx={sx ?? {}}
      disableElevation
      type={type}
    >
      {t('next')}
    </Button>
  );
};
