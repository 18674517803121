import {
  DigitalAdvertisingAttribute,
  IWebsiteGrade,
  OptimizationLevel,
  SocialAndLocalPresenceAttribute,
  WebsiteBuildAttribute,
  WebsiteScoreSubCategory,
} from './Report';

const metrics = (
  grade: IWebsiteGrade,
  sectionName: WebsiteScoreSubCategory,
  optimizationLevel: keyof typeof OptimizationLevel
): WebsiteBuildAttribute[] =>
  Object.keys(grade.scoreDetails)
    .filter((scoreDetailKey) =>
      Object.values(
        sectionName === WebsiteScoreSubCategory.WEBSITE_BUILD
          ? WebsiteBuildAttribute
          : sectionName === WebsiteScoreSubCategory.SOCIAL_AND_LOCAL_PRESENCE
          ? SocialAndLocalPresenceAttribute
          : DigitalAdvertisingAttribute
      ).includes(scoreDetailKey)
    )
    .filter(
      (scoreDetailKey) =>
        (
          grade.scoreDetails[
            scoreDetailKey as WebsiteBuildAttribute | SocialAndLocalPresenceAttribute | DigitalAdvertisingAttribute
          ] as {
            optimizationLevel: keyof typeof OptimizationLevel;
          }
        ).optimizationLevel === optimizationLevel
    ) as WebsiteBuildAttribute[];

export const groupMetrics = ({ grade, selected }: { grade: IWebsiteGrade; selected: WebsiteScoreSubCategory }) => {
  return {
    lowMetrics: metrics(grade, selected, 'LOW'),
    mediumMetrics: metrics(grade, selected, 'MEDIUM'),
    highMetrics: metrics(grade, selected, 'HIGH'),
  };
};
