import styled from 'styled-components';
import { FlexBoxRow } from '../FlexBoxRow';
import { LinkProps, Typography, useMediaQuery } from '@mui/material';
import { LyraTheme as theme } from 'src/components/theme/lyra/Theme';

import LiQDesktopIcon from 'src/assets/websitegrader/logo/desktop/mark.svg';
import LiQDesktopText from 'src/assets/websitegrader/logo/desktop/logo.svg';
import LiQMobileIcon from 'src/assets/websitegrader/logo/mobile/logo.svg';

const Link = styled('a').attrs<LinkProps & { $ismobile: boolean }>((props) => ({ ...props }))(
  ({ $ismobile, theme }) => ({
    cursor: 'pointer',
    height: $ismobile ? undefined : theme.spacing(4.5),
  })
);

export const WebsiteGraderNavBar = (): JSX.Element => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <FlexBoxRow sx={{ alignSelf: 'stretch', justifyContent: 'space-between', width: '100%' }}>
      {isMobile ? (
        <Link href="https://www.localiq.com/" target="_blank" tabIndex={-1} aria-label="LOCALiQ" $ismobile={isMobile}>
          <img src={LiQMobileIcon} id="logo" alt="LOCALiQ" />
        </Link>
      ) : (
        <FlexBoxRow sx={{ gap: 1.25 }}>
          <Link href="https://www.localiq.com/" target="_blank" tabIndex={-1} aria-label="LOCALiQ" $ismobile={isMobile}>
            <img src={LiQDesktopIcon} id="logo" alt="LOCALiQ" />
          </Link>
          <Link href="https://www.localiq.com/" target="_blank" tabIndex={-1} aria-label="LOCALiQ" $ismobile={isMobile}>
            <img src={LiQDesktopText} id="logo" alt="LOCALiQ" />
          </Link>
        </FlexBoxRow>
      )}
      <Typography variant={isMobile ? 'h4' : 'h3'} textAlign={'center'}>
        {'Website Grader+'}
      </Typography>
      {/* same width as icon */}
      {isMobile ? <></> : <div style={{ width: '188px' }} />}
    </FlexBoxRow>
  );
};
