import { useTranslation } from 'react-i18next';
import { ScoreName } from 'src/enums/ScoreName';
import { GradeUtils } from 'src/utils/GradeUtils';
import { TableRowMobile } from './TableRowMobile';
import { ServiceType } from 'src/enums/ServiceType';
import { TableCell, TableRow } from '@mui/material';

type Props = {
  title: string;
  scorename: ScoreName;
  you?: string | number;
  competitors?: string | number;
  withCompetitorTooltip?: boolean;
};

export const YouVsCompetitorMobile = ({
  title,
  scorename,
  you,
  competitors,
  withCompetitorTooltip,
}: Props): JSX.Element => {
  const { t } = useTranslation([ServiceType.FACEBOOK]);
  const highlightColor = GradeUtils.getScoreColorLight(scorename);

  return (
    <>
      <TableRow>
        <TableCell padding="none" colSpan={2} sx={{ px: 1 }}>
          {title && <>{title}</>}
        </TableCell>
      </TableRow>
      <TableRowMobile text={t('grade.you')} value={you ?? '-'} isBold={true} highlightColor={highlightColor} />
      <TableRowMobile withTooltip={withCompetitorTooltip} text={t('grade.competitorAvg')} value={competitors ?? '-'} />
    </>
  );
};
