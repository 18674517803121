import { WebsiteScoreSubCategory } from 'src/pages/websiteGrader/Report';
import { GradeUtils } from 'src/utils/GradeUtils';
import { ScoreName } from 'src/enums/ScoreName';
import { LyraTheme as theme } from 'src/components/theme/lyra/Theme';
import { Typography, TypographyProps, useMediaQuery } from '@mui/material';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { useTranslation } from 'react-i18next';
import { ResponsiveRow } from 'src/components/common/ResponsiveRow';

import FoundationIcon from 'src/assets/websitegrader/gradebar/Foundation.svg';
import IntermediateIcon from 'src/assets/websitegrader/gradebar/Intermediate.svg';
import AdvancedIcon from 'src/assets/websitegrader/gradebar/Advanced.svg';

import styled from 'styled-components';

export const BodyXLarge = styled(Typography).attrs<TypographyProps>((props) => ({ variant: 'body1', ...props }))(
  () => ({
    fontSize: useMediaQuery(theme.breakpoints.down('sm')) ? '21px' : '24px',
    lineHeight: useMediaQuery(theme.breakpoints.down('sm')) ? '30px' : '36px',
  })
);

const OverlineText = styled(Typography).attrs((props) => ({ variant: 'overline', ...props }))(() => ({
  letterSpacing: '0.75px',
}));

interface IKeyTakeAwayProps {
  $sectionScore: number;
  sectionName: WebsiteScoreSubCategory;
}

export const KeyTakeAway = ({ $sectionScore, sectionName }: IKeyTakeAwayProps) => {
  const { t } = useTranslation(['websiteGrader']);
  const scoreName = GradeUtils.getScoreNameFromNumber($sectionScore);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const Icon = (() => {
    switch (scoreName) {
      case ScoreName.FOUNDATIONAL:
        return FoundationIcon;
      case ScoreName.INTERMEDIATE:
        return IntermediateIcon;
      case ScoreName.ADVANCED:
        return AdvancedIcon;
    }
  })();
  const { border, background } = ((): { border: string; background: string } => {
    switch (scoreName) {
      case ScoreName.FOUNDATIONAL:
        return {
          border: `${theme.spacing(0.25)} solid ${theme.palette.score?.foundational}`,
          background: theme.palette.score?.foundational as string,
        };
      case ScoreName.INTERMEDIATE:
        return {
          border: `${theme.spacing(0.25)} solid ${theme.palette.score?.intermediate}`,
          background: theme.palette.score?.intermediate as string,
        };
      case ScoreName.ADVANCED:
        return {
          border: `${theme.spacing(0.25)} solid ${theme.palette.score?.advanced}`,
          background: theme.palette.score?.advanced as string,
        };
      default:
        return {
          border: 'none',
          background: 'none',
        };
    }
  })();
  return (
    <ResponsiveRow
      breakpoint={'sm'}
      sx={{ paddingX: isMobile ? 0 : 10, gap: 5, alignItems: 'flex-start', alignSelf: 'stretch' }}
    >
      <FlexBoxColumn sx={{ border, background, borderRadius: '12px', width: isMobile ? '100%' : '160px' }}>
        <div style={{ padding: '12px', height: '80px', width: '80px' }}>
          <img src={Icon} height={80} width={80} />
        </div>
        <FlexBoxColumn
          sx={{
            alignSelf: 'stretch',
            gap: 0.5,
            background: 'white',
            padding: '8px 16px 12px 16px',
            borderBottomLeftRadius: '12px',
            borderBottomRightRadius: '12px',
          }}
        >
          <OverlineText
            sx={{ textAlign: 'center', width: 'min-content' }}
            dangerouslySetInnerHTML={{ __html: t(`score.keyTakeAway.${sectionName}.iconName`) }}
          />
          <Typography variant="body1" sx={{ lineHeight: '16px', fontWeight: 600 }}>
            {scoreName}
          </Typography>
        </FlexBoxColumn>
      </FlexBoxColumn>
      <FlexBoxColumn sx={{ gap: 1, alignItems: 'flex-start', flex: '1 0 0' }}>
        <Typography variant="h3">{t(`score.keyTakeAway.${sectionName}.name`)}</Typography>
        <BodyXLarge>{t(`score.keyTakeAway.${sectionName}.text_${scoreName.toLowerCase()}`)}</BodyXLarge>
      </FlexBoxColumn>
    </ResponsiveRow>
  );
};
