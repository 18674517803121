import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { BusinessType } from 'src/enums/BusinessType';
import { HttpError } from '../common/adGenerator/ErrorBoundary';
import { IWebsiteGrade } from 'src/pages/websiteGrader/Report';

export enum WebsiteGraderStep {
  'COLLECT_USER_DETAILS',
  'COLLECT_BUSINESS_DETAILS',
  'LOADING_RESULTS',
  'ERROR',
}

export interface IWebsiteGraderUserDetails {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
}

export interface IWebsiteGraderBuinsessDetails {
  businessName: string;
  website: string;
  businessType: BusinessType | string;
}

export interface IWebsiteGraderContext {
  currentStep: WebsiteGraderStep;
  setCurrentStep: React.Dispatch<React.SetStateAction<WebsiteGraderStep>>;
  userDetails: IWebsiteGraderUserDetails;
  setUserDetails: React.Dispatch<React.SetStateAction<IWebsiteGraderUserDetails>>;
  businessDetails: IWebsiteGraderBuinsessDetails;
  setBusinessDetails: React.Dispatch<React.SetStateAction<IWebsiteGraderBuinsessDetails>>;
  error: HttpError | null;
  setError: React.Dispatch<React.SetStateAction<HttpError | null>>;
  processing: boolean;
  setProcessing: React.Dispatch<React.SetStateAction<boolean>>;
  competitorScore: (Omit<IWebsiteGrade, 'competitors'> & { competitorId: number }) | undefined;
  setCompetitorScore: React.Dispatch<
    React.SetStateAction<(Omit<IWebsiteGrade, 'competitors'> & { competitorId: number }) | undefined>
  >;
}

const DefaultUserDetails = { firstName: '', lastName: '', phone: '', email: '' };
const DefaultBusinessDetails = { businessName: '', website: '', businessType: '' };
const WebsiteGraderContext = createContext({} as IWebsiteGraderContext);

export const WebsiteGraderContextProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [currentStep, setCurrentStep] = useState<WebsiteGraderStep>(WebsiteGraderStep.COLLECT_USER_DETAILS);
  const [userDetails, setUserDetails] = useState<IWebsiteGraderUserDetails>({ ...DefaultUserDetails });
  const [businessDetails, setBusinessDetails] = useState<IWebsiteGraderBuinsessDetails>({ ...DefaultBusinessDetails });
  const [error, setError] = useState<HttpError | null>(null);
  const [processing, setProcessing] = useState<boolean>(false);
  const [competitorScore, setCompetitorScore] = useState<
    (Omit<IWebsiteGrade, 'competitors'> & { competitorId: number }) | undefined
  >(undefined);

  const defaultContextValue = useMemo(
    () => ({
      currentStep,
      setCurrentStep,
      userDetails,
      setUserDetails,
      businessDetails,
      setBusinessDetails,
      error,
      setError,
      processing,
      setProcessing,
      competitorScore,
      setCompetitorScore,
    }),
    [currentStep, userDetails, businessDetails, error, processing, competitorScore]
  );

  return <WebsiteGraderContext.Provider value={defaultContextValue}>{children}</WebsiteGraderContext.Provider>;
};

export const useWebsiteGraderContext = (): IWebsiteGraderContext => useContext(WebsiteGraderContext);

// export const withWebsiteGraderProvider = (component: JSX.Element): JSX.Element => (
//   <WebsiteGraderContextProvider>{component}</WebsiteGraderContextProvider>
// );
