import { Box, BoxProps } from '@mui/material';
import styled from 'styled-components';

export const FlexBoxColumn = styled(Box)(({ theme, sx }) => {
  const sxl: BoxProps['sx'] = sx || {};
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // theme'ng isn't available
    ...theme.unstable_sx({
      ...sxl,
    }),
  };
});
