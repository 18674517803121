import styled from 'styled-components';
import { FlexBoxColumn } from '../FlexBoxColumn';
import {
  GetLayoutFooterHeight,
  GetLayoutMaxWidth,
  GetLayoutSubFooterHeight,
  GetLayoutWidth,
  NavBarHeightWithNoBorder,
} from '../adGenerator/Container';
import { Typography, useMediaQuery } from '@mui/material';
import { LyraTheme as theme } from 'src/components/theme/lyra/Theme';
import { SubFooter } from 'src/components/common/adGenerator/SubFooter';
import { ReactNode } from 'react';
import { AdGeneratorBackground } from '../adGenerator/Background';
import { WebsiteGraderNavBar } from './NavBar';
import { Footer } from '../adGenerator/Footer';

import AiSparkle from 'src/assets/adgenerator/aisparkle.svg';

interface ILayoutAttrs {
  // screen variations
  $isMobile: boolean;
  $isLarge: boolean;
  $isXLarge: boolean;
}

const FlexLayout = styled(FlexBoxColumn).attrs<ILayoutAttrs>(({ $isMobile, $isLarge, $isXLarge }: ILayoutAttrs) => ({
  $isMobile,
  $isLarge,
  $isXLarge,
}))(({ $isMobile, $isLarge, $isXLarge }) => ({
  gap: theme.spacing(3),
  width: GetLayoutWidth(true, $isMobile),
  maxWidth: GetLayoutMaxWidth(true, $isMobile),
  justifyContent: 'center',
  minHeight: `calc(
    100vh
    -
    ${NavBarHeightWithNoBorder}
    -
    ${GetLayoutSubFooterHeight($isXLarge, $isLarge, $isMobile)}
    -
    ${GetLayoutFooterHeight($isXLarge, $isLarge, $isMobile)}
    -
    192px
  )`,
  padding: $isMobile ? '40px 40px' : '96px 0px',
}));

export const ContainerForLoadingAndErrorComponents = ({
  $aiTool = true,
  children,
}: {
  $aiTool?: boolean;
  children: ReactNode;
}) => {
  const isXLarge = useMediaQuery(theme.breakpoints.up('lg'));
  const isLarge = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <AdGeneratorBackground>
      <FlexBoxColumn
        sx={{
          paddingX: $aiTool ? 3 : isMobile ? 2 : 5,
          paddingTop: $aiTool ? 3 : isMobile ? 2 : 5,
          alignSelf: 'stretch',
          alignItems: 'flex-start',
          zIndex: 100,
        }}
      >
        <WebsiteGraderNavBar />
      </FlexBoxColumn>
      <FlexLayout $isLarge={isLarge} $isMobile={isMobile} $isXLarge={isXLarge}>
        {$aiTool ? (
          <FlexBoxColumn gap={3} sx={{ alignSelf: 'stretch' }}>
            <img src={AiSparkle} id="AI" alt="AISparkle" height={36} />
            <Typography variant="h1" textAlign={'center'} color={'grey.900'}>
              {'Google Ads AI Copy Tool'}
            </Typography>
          </FlexBoxColumn>
        ) : isMobile ? (
          <Typography variant="h1" textAlign={'center'} color={'grey.900'}>
            {'Website Grader'}
          </Typography>
        ) : (
          <></>
        )}
        {children}
      </FlexLayout>
      <SubFooter />
      <Footer />
    </AdGeneratorBackground>
  );
};
