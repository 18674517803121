import {
  BodyXSmall,
  GradeBubbleText,
  ScoreBox,
  ScoreDivider,
} from 'src/components/common/websiteGrader/resultPage/header/HeaderScoreBubble';
import { Typography } from '@mui/material';
import { FlexBoxRow } from 'src/components/common/FlexBoxRow';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { IWebsiteGrade, WebsiteScoreSubCategory } from 'src/pages/websiteGrader/Report';
import { useTranslation } from 'react-i18next';

interface ITabLabelProps {
  grade: IWebsiteGrade;
  sectionName: WebsiteScoreSubCategory;
  $selected?: boolean;
}

export const TabLabel = ({ grade, sectionName, $selected = false }: ITabLabelProps) => {
  const { t } = useTranslation(['websiteGrader']);
  const noOfMetrics = (() => {
    switch (sectionName) {
      case WebsiteScoreSubCategory.WEBSITE_BUILD:
        return 13;
      case WebsiteScoreSubCategory.SOCIAL_AND_LOCAL_PRESENCE:
        return 3;
      case WebsiteScoreSubCategory.DIGITAL_ADVERTISING:
        return 3;
    }
  })();
  return (
    <FlexBoxColumn sx={{ gap: 1 }}>
      <FlexBoxRow sx={{ gap: 2 }}>
        <ScoreBox $score={grade.subCategories[sectionName].subScore} $ismobile={false}>
          <GradeBubbleText sx={{ fontWeight: 600 }}>{grade.subCategories[sectionName].subScore}</GradeBubbleText>
          <ScoreDivider />
          <Typography variant="body1" sx={{ fontWeight: 600, lineHeight: '16px' }}>
            {100}
          </Typography>
        </ScoreBox>
        <GradeBubbleText
          dangerouslySetInnerHTML={{ __html: t(`score.header.${sectionName}`) }}
          sx={{ textAlign: 'left', width: 'min-content', fontWeight: $selected ? 600 : 400, textTransform: 'none' }}
        />
      </FlexBoxRow>
      <BodyXSmall sx={{ textTransform: 'none' }}>{`${noOfMetrics} Performance Metrics Analyzed`}</BodyXSmall>
    </FlexBoxColumn>
  );
};
