import { Breakpoint, useMediaQuery } from '@mui/material';
import { FlexBoxRow } from './FlexBoxRow';
import styled from 'styled-components';

export const ResponsiveRow = styled(FlexBoxRow)<{
  breakpoint?: number | Breakpoint;
  flexDirection?: 'column' | 'column-reverse' | 'row' | 'row-reverse';
  reverse?: 'true' | 'false';
}>(({ theme, breakpoint, flexDirection, reverse = false }) => ({
  flexDirection:
    flexDirection ??
    (useMediaQuery(theme.breakpoints.down(breakpoint ?? 'sm')) ? (reverse ? 'column-reverse' : 'column') : 'row'),
}));
