import { LinearProgress } from '@mui/material';
import { useProgress } from './useProgress';
import { useEffect, useState } from 'react';

type Props = {
  callback?: () => void;
  throttle?: boolean;
};

export const StyledLinearProgress = ({ callback, throttle }: Props): JSX.Element => {
  const { value } = useProgress({ callback, throttle });
  const [middle, setMiddle] = useState(value * 0.5208);
  const [last, setLast] = useState(value - (value * 0.1) / 100);
  const [greyFirst, setGreyFirst] = useState(value + (value * 0.1) / 100);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setMiddle(value * 0.5208);
      setLast(value - (value * 0.1) / 100);
      setGreyFirst(value + (value * 0.1) / 100);
    }, 250);
    return () => clearTimeout(timeId);
  }, [value]);

  return (
    <LinearProgress
      value={value}
      variant="determinate"
      style={{
        width: '100%',
        background: `linear-gradient(90deg, #0046FF ${0}%, #F03C4B ${middle}%, #FF6437 ${last}%, #AEAEAD ${greyFirst}%, #AEAEAD ${100}%)`,
      }}
    />
  );
};
