import { Box, Card, Divider, dividerClasses, Typography, useMediaQuery } from '@mui/material';
import { ScoreName } from 'src/enums/ScoreName';
import { TFunction, Trans, useTranslation } from 'react-i18next';
import { GradeUtils } from 'src/utils/GradeUtils';
import {
  GoogleAdsScoreSection,
  GoogleScoreSectionType,
  GoogleShoppingScoreSection,
} from 'src/enums/google/ScoreSection';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import { useContext } from 'react';
import { GoogleAdTypeContext } from 'src/components/providers/adType';
import { GoogleAdType } from 'src/enums/GoogleAdType';
import { ServiceType } from 'src/enums/ServiceType';
import { GGradeScoreType, useGGradeScore } from 'src/hooks/google/useGGradeScore';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { BoldTypography } from 'src/components/common/styled/BoldTypography';
import { ResponsiveRow } from '../../ResponsiveRow';
import { ReactComponent as Warning } from 'src/assets/WarningIcon.svg';
import { ReactComponent as DiamondTurnRight } from 'src/assets/DiamondTurnRight.svg';
import { ReactComponent as CircleCheck } from 'src/assets/CircleCheck.svg';
import { useGradeSectionScore } from 'src/hooks/common/useGradeSectionScore';
import { ServiceContext } from 'src/components/providers/service';
import { ShoppingGradeScoreType, useShoppingGradeScore } from 'src/hooks/shopping/useShoppingGradeScrore';
import { IGoogleGrade } from 'src/types/google/GoogleGrade';
import { IShoppingGrade } from 'src/types/shopping/ShoppingGrade';

import styled from 'styled-components';

interface Props {
  sectionName: GoogleScoreSectionType;
}

interface TransProps {
  sectionName: GoogleScoreSectionType;
  scorename: ScoreName;
  t: TFunction;
}

const GET_QUALITY_SCORE_ATTRS = (
  searchGrade: IGoogleGrade | undefined
): {
  weightedQualityScore: string;
  pointsToIncrease: string;
  savingsSpendLocal: string;
  savingsClicks: string;
} => {
  return {
    weightedQualityScore: (searchGrade?.qualityScore.duration90.weightedQualityScore ?? 0).toLocaleString(
      process.env.REACT_APP_LOCALE,
      {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      }
    ),
    pointsToIncrease: (searchGrade?.qualityScore.duration90.pointsToIncrease ?? 0).toLocaleString(
      process.env.REACT_APP_LOCALE,
      {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      }
    ),
    savingsSpendLocal: (searchGrade?.qualityScore.duration90.savingsSpendLocal ?? 0).toLocaleString(
      process.env.REACT_APP_LOCALE,
      {
        style: 'currency',
        maximumFractionDigits: 0,
        currency: searchGrade?.currencyCode ?? 'USD',
      }
    ),
    savingsClicks: (searchGrade?.qualityScore.duration90.savingsClicks ?? 0).toLocaleString(
      process.env.REACT_APP_LOCALE,
      {
        maximumFractionDigits: 0,
      }
    ),
  };
};

const GET_IMPRESSION_SHARE_ATTRS = (
  searchGrade: IGoogleGrade | undefined
): {
  budgetLostImpressionShare: string;
  rankLostImpressionShare: string;
  additionalImpressions: string;
  additionalClicks: string;
} => {
  return {
    budgetLostImpressionShare: ((searchGrade?.impressionShare.budgetLostImpressionShare ?? 0) / 100).toLocaleString(
      process.env.REACT_APP_LOCALE,
      { maximumFractionDigits: 0, style: 'percent' }
    ),
    rankLostImpressionShare: ((searchGrade?.impressionShare.rankLostImpressionShare ?? 0) / 100).toLocaleString(
      process.env.REACT_APP_LOCALE,
      { maximumFractionDigits: 0, style: 'percent' }
    ),
    additionalImpressions: (searchGrade?.impressionShare.additionalImpressions ?? 0).toLocaleString(
      process.env.REACT_APP_LOCALE,
      {
        maximumFractionDigits: 0,
      }
    ),
    additionalClicks: (searchGrade?.impressionShare.additionalClicks ?? 0).toLocaleString(
      process.env.REACT_APP_LOCALE,
      {
        maximumFractionDigits: 0,
      }
    ),
  };
};

const GET_SHOPPING_ACCOUNT_STRUCTURE_ATTRS = (
  shoppingGrade: IShoppingGrade | undefined
): {
  topProducts: string;
  topConversionsPercentage: string;
  topConversionsValue: string;
} => {
  return {
    topProducts: (shoppingGrade?.accountStructureMetrics.topConversions ?? 0).toLocaleString(
      process.env.REACT_APP_LOCALE,
      {
        maximumFractionDigits: 0,
      }
    ),
    topConversionsPercentage: (shoppingGrade
      ? shoppingGrade.accountStructureMetrics.topConversionsValue /
          shoppingGrade.shoppingPerformanceMetrics.conversionValue || 0
      : 0
    ).toLocaleString(process.env.REACT_APP_LOCALE, { maximumFractionDigits: 0, style: 'percent' }),
    topConversionsValue: (shoppingGrade?.accountStructureMetrics.topConversionsValue ?? 0).toLocaleString(
      process.env.REACT_APP_LOCALE,
      { maximumFractionDigits: 0 }
    ),
  };
};

const TransLocal = ({ sectionName, scorename, t }: TransProps): JSX.Element => {
  const { isSearch, isShopping } = useContext(GoogleAdTypeContext);
  const sectionKey = GradeUtils.getSectionKey(sectionName);
  const { searchGrade } = isSearch ? useGGradeScore({ sectionName: undefined }) : ({} as GGradeScoreType);
  const { shoppingGrade } = isShopping
    ? useShoppingGradeScore({ sectionName: undefined })
    : ({} as ShoppingGradeScoreType);

  switch (sectionName) {
    case GoogleAdsScoreSection.QUALITY_SCORE:
      return (
        <Trans
          t={t}
          i18nKey={t('grade.summaryContent.' + scorename.toLowerCase(), {
            context: sectionKey,
            ...{ ...GET_QUALITY_SCORE_ATTRS(searchGrade) },
          })}
        />
      );
    case GoogleAdsScoreSection.IMPRESSION_SHARE:
      return (
        <Trans
          t={t}
          i18nKey={t('grade.summaryContent.' + scorename.toLowerCase(), {
            context: sectionKey,
            ...{ ...GET_IMPRESSION_SHARE_ATTRS(searchGrade) },
          })}
        />
      );
    case GoogleShoppingScoreSection.ACCOUNT_STRUCTURE:
      if (shoppingGrade?.accountStructureMetrics.topConversions === 0) {
        return (
          <Trans
            t={t}
            i18nKey={t('grade.summaryContent.' + ScoreName.FOUNDATIONAL.toLowerCase(), { context: `${sectionKey}_0` })}
          />
        );
      } else {
        return (
          <Trans
            t={t}
            i18nKey={t('grade.summaryContent.' + scorename.toLowerCase(), {
              context: sectionKey,
              ...{ ...GET_SHOPPING_ACCOUNT_STRUCTURE_ATTRS(shoppingGrade) },
            })}
          />
        );
      }
    case GoogleShoppingScoreSection.CAMPAIGN_RANKINGS:
      return (
        <Trans
          t={t}
          i18nKey={t('grade.summaryContent.' + scorename.toLowerCase(), {
            context: sectionKey,
            campaigns:
              (shoppingGrade?.campaignRankings &&
                GradeUtils.sumOfLowAndMediumOptimizationLevels(shoppingGrade.campaignRankings).toLocaleString(
                  process.env.REACT_APP_LOCALE,
                  {
                    maximumFractionDigits: 0,
                  }
                )) ??
              '0',
          })}
        />
      );
    default:
      return <Trans t={t} i18nKey={t('grade.summaryContent.' + scorename.toLowerCase(), { context: sectionKey })} />;
  }
};

export const ColoredDivider = styled(Divider)<{ bordercolor: string }>`
  &.${dividerClasses.withChildren}:before, &.${dividerClasses.withChildren}:after {
    border-top: ${({ theme }) => theme.spacing(0.125)} solid
      ${({ bordercolor }: { bordercolor: string }) => bordercolor};
  }
`;

export const GetIcon = ({ scorename }: { scorename: ScoreName }): JSX.Element => {
  switch (scorename) {
    case ScoreName.INTERMEDIATE:
      return <DiamondTurnRight />;
    case ScoreName.ADVANCED:
      return <CircleCheck />;
    default:
      return <Warning />;
  }
};

export const ScoreSummarySearch = ({ sectionName }: Props): JSX.Element => {
  const [service] = useContext(ServiceContext);
  const { t } = useTranslation([ServiceType.GOOGLE]);
  const { scoreName } = useGradeSectionScore({ service, sectionName });
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down(600));

  return (
    <>
      {scoreName ? (
        <Card
          sx={{ borderColor: GradeUtils.getScoreColor(scoreName, OrionTheme), borderRadius: 0, p: 4, width: '100%' }}
        >
          <ResponsiveRow breakpoint={'sm'} sx={{ alignItems: 'flex-start', gap: 2, justifyContent: 'flex-start' }}>
            {isMobile ? (
              <Box sx={{ width: '100%' }}>
                <ColoredDivider bordercolor={GradeUtils.getScoreColor(scoreName, OrionTheme)}>
                  {GetIcon({ scorename: scoreName })}
                </ColoredDivider>
              </Box>
            ) : (
              <Box>{GetIcon({ scorename: scoreName })}</Box>
            )}
            <FlexBoxColumn sx={{ alignItems: 'flex-start', gap: isMobile ? 2 : 1 }}>
              <BoldTypography variant={'h5'}>
                <Trans
                  t={t}
                  i18nKey={t('grade.summaryHeadline.' + scoreName.toLowerCase(), {
                    context: GradeUtils.getSectionKey(sectionName),
                  })}
                />
              </BoldTypography>
              <Typography variant="body1">
                <TransLocal sectionName={sectionName} scorename={scoreName} t={t} />
              </Typography>
            </FlexBoxColumn>
          </ResponsiveRow>
        </Card>
      ) : (
        <></>
      )}
    </>
  );
};

export const ScoreSummaryDisplay = ({ sectionName }: Props): JSX.Element => {
  const [service] = useContext(ServiceContext);
  const { t } = useTranslation([GoogleAdType.DISPLAY]);
  const { scoreName } = useGradeSectionScore({ service, sectionName });
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down(600));

  return (
    <>
      {scoreName ? (
        <Card
          sx={{ borderColor: GradeUtils.getScoreColor(scoreName, OrionTheme), borderRadius: 0, p: 4, width: '100%' }}
        >
          <ResponsiveRow breakpoint={'sm'} sx={{ alignItems: 'flex-start', gap: 2, justifyContent: 'flex-start' }}>
            {isMobile ? (
              <Box sx={{ width: '100%' }}>
                <ColoredDivider bordercolor={GradeUtils.getScoreColor(scoreName, OrionTheme)}>
                  {GetIcon({ scorename: scoreName })}
                </ColoredDivider>
              </Box>
            ) : (
              <Box>{GetIcon({ scorename: scoreName })}</Box>
            )}
            <FlexBoxColumn sx={{ alignItems: 'flex-start', gap: isMobile ? 2 : 1 }}>
              <BoldTypography variant={'h5'}>
                <Trans
                  t={t}
                  i18nKey={t('grade.summaryHeadline.' + scoreName.toLowerCase(), {
                    context: GradeUtils.getSectionKey(sectionName),
                  })}
                />
              </BoldTypography>
              <Typography variant="body1">
                <TransLocal sectionName={sectionName} scorename={scoreName} t={t} />
              </Typography>
            </FlexBoxColumn>
          </ResponsiveRow>
        </Card>
      ) : (
        <></>
      )}
    </>
  );
};

export const ScoreSummaryShopping = ({ sectionName }: Props): JSX.Element => {
  const [service] = useContext(ServiceContext);
  const { t } = useTranslation([GoogleAdType.SHOPPING]);
  const { scoreName } = useGradeSectionScore({ service, sectionName });
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down(600));

  return (
    <>
      {scoreName ? (
        <Card
          sx={{ borderColor: GradeUtils.getScoreColor(scoreName, OrionTheme), borderRadius: 0, p: 4, width: '100%' }}
        >
          <ResponsiveRow breakpoint={'sm'} sx={{ alignItems: 'flex-start', gap: 2, justifyContent: 'flex-start' }}>
            {isMobile ? (
              <Box sx={{ width: '100%' }}>
                <ColoredDivider bordercolor={GradeUtils.getScoreColor(scoreName, OrionTheme)}>
                  {GetIcon({ scorename: scoreName })}
                </ColoredDivider>
              </Box>
            ) : (
              <Box>{GetIcon({ scorename: scoreName })}</Box>
            )}
            <FlexBoxColumn sx={{ alignItems: 'flex-start', gap: isMobile ? 2 : 1 }}>
              <BoldTypography variant={'h5'}>
                <Trans
                  t={t}
                  i18nKey={t('grade.summaryHeadline.' + scoreName.toLowerCase(), {
                    context: GradeUtils.getSectionKey(sectionName),
                  })}
                />
              </BoldTypography>
              <Typography variant="body1">
                <TransLocal sectionName={sectionName} scorename={scoreName} t={t} />
              </Typography>
            </FlexBoxColumn>
          </ResponsiveRow>
        </Card>
      ) : (
        <></>
      )}
    </>
  );
};
