import { IWebsiteGrade } from 'src/pages/websiteGrader/Report';
import { FlexBoxRow } from '../../../FlexBoxRow';
import { Divider, Link, Typography, useMediaQuery } from '@mui/material';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { GradeUtils } from 'src/utils/GradeUtils';
import { LyraTheme as theme } from 'src/components/theme/lyra/Theme';
import { ScoreBox, ScoreDivider, ScoreBubblePlacing, BodyXSmall, GradeBubbleText } from './HeaderScoreBubble';
import { useTranslation } from 'react-i18next';
import { Delete } from '@mui/icons-material';
import { DeleteCompetitor } from '../../Utils';
import { useWebsiteGraderContext } from 'src/components/providers/websiteGrader';

import Caret from 'src/assets/Caret.svg';
import styled from 'styled-components';

const extractDomain = (url: string): string => {
  try {
    const urlObject = new URL(url);
    return urlObject.hostname;
    // eslint-disable-next-line
  } catch (error) {
    // Handle invalid URL
    return url.split('/')[0];
  }
};

const LinkToCompetitorWebsite = styled(Link)(({ theme }) => ({
  color: theme.palette.grey['900'],
  cursor: 'pointer',
  fontSize: useMediaQuery(theme.breakpoints.down('sm')) ? '18px' : '20px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: useMediaQuery(theme.breakpoints.down('sm')) ? '18px' : '20px',
  textDecorationLine: 'underline',
  textDecorationStyle: 'solid',
  textDecorationSkipInk: 'none',
  textDecorationThickness: 'auto',
  textUnderlineOffset: 'auto',
  textUnderlinePosition: 'from-font',
  textDecorationColor: theme.palette.grey['900'],
  maxWidth: '247px',
  overflow: 'hidden',
}));

export const CompetitorBubbleBox = styled(FlexBoxRow)(({ theme }) => ({
  padding: theme.spacing(1.5),
  gap: theme.spacing(1.5),
  alignSelf: 'stretch',
  justifyContent: 'flex-start',
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  borderBottomWidth: theme.spacing(0.125),
  borderBottomColor: 'rgba(0, 0, 0, 0.15)', // neutral grey
}));

export const CompetitorScoreBubble = ({
  uuid,
  competitorScore,
}: {
  uuid: string;
  competitorScore: Omit<IWebsiteGrade, 'competitors'> & { competitorId: number };
}) => {
  const { setCompetitorScore } = useWebsiteGraderContext();
  const { t } = useTranslation(['websiteGrader']);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const deleteCompetitor = async () => {
    await DeleteCompetitor({ uuid, competitorId: competitorScore.competitorId });
    setCompetitorScore(undefined);
  };

  return (
    <FlexBoxRow sx={{ alignSelf: isMobile ? 'center' : 'stretch', justifyContent: 'stretch' }}>
      <FlexBoxRow
        sx={{
          width: isMobile ? 'initial' : ScoreBubblePlacing(competitorScore.overallScore, true).width,
          justifyContent: ScoreBubblePlacing(competitorScore.overallScore).justifyContent,
        }}
      >
        <FlexBoxColumn
          sx={{
            position: 'relative',
            background: theme.palette.common.white,
            borderRadius: theme.spacing(1.5),
            boxShadow: `0px 4px 24px 0px rgba(21, 26, 41, 0.10)`,
          }}
        >
          <img src={Caret} style={{ position: 'absolute', top: '-12px' }} alt="Competitor Score Pointer Up Icon" />
          <CompetitorBubbleBox>
            <ScoreBox $score={competitorScore.overallScore} $ismobile={isMobile}>
              <GradeBubbleText sx={{ fontWeight: 600 }}>{competitorScore.overallScore}</GradeBubbleText>
              <ScoreDivider />
              <Typography variant="body1" sx={{ fontWeight: 600, lineHeight: '16px' }}>
                {100}
              </Typography>
            </ScoreBox>
            <FlexBoxColumn sx={{ gap: 1, alignItems: 'flex-start', zIndex: 10 }}>
              <FlexBoxRow sx={{ gap: 0.5, alignSelf: 'stretch', justifyContent: 'space-between' }}>
                <LinkToCompetitorWebsite href={competitorScore.domain} target="_blank">
                  {extractDomain(competitorScore.domain)}
                </LinkToCompetitorWebsite>
                <Delete sx={{ color: theme.palette.grey[500], cursor: 'pointer' }} onClick={() => deleteCompetitor()} />
              </FlexBoxRow>
              <BodyXSmall>
                {t('score.header.competitorGrade')}&nbsp;
                {GradeUtils.getScoreNameFromNumber(competitorScore.overallScore)}
              </BodyXSmall>
            </FlexBoxColumn>
          </CompetitorBubbleBox>
          <StyledDivider sx={{ width: '100%' }} />
          <FlexBoxRow sx={{ gap: theme.spacing(1.5), p: theme.spacing(1.5), alignSelf: 'stretch' }}>
            <FlexBoxColumn sx={{ gap: theme.spacing(0.5), flex: '1 0 0' }}>
              <BodyXSmall sx={{ textAlign: 'center', width: 'min-content' }}>
                {t('score.header.websiteBuild')}
              </BodyXSmall>
              <Typography variant="h5">
                {competitorScore.subCategories['websiteBuild'].subScore}/{100}
              </Typography>
            </FlexBoxColumn>
            <FlexBoxColumn sx={{ gap: theme.spacing(0.5), flex: '1 0 0' }}>
              <BodyXSmall
                sx={{ textAlign: 'center', width: 'min-content' }}
                dangerouslySetInnerHTML={{ __html: t('score.header.social') }}
              />
              <Typography variant="h5">
                {competitorScore.subCategories['social'].subScore}/{100}
              </Typography>
            </FlexBoxColumn>
            <FlexBoxColumn sx={{ gap: theme.spacing(0.5), flex: '1 0 0' }}>
              <BodyXSmall sx={{ textAlign: 'center', width: 'min-content' }}>
                {t('score.header.advertising')}
              </BodyXSmall>
              <Typography variant="h5">
                {competitorScore.subCategories['advertising'].subScore}/{100}
              </Typography>
            </FlexBoxColumn>
          </FlexBoxRow>
        </FlexBoxColumn>
      </FlexBoxRow>
    </FlexBoxRow>
  );
};
