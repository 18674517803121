import { ResponsiveRow } from 'src/components/common/ResponsiveRow';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { Button, Typography, useMediaQuery } from '@mui/material';
import { BodyXLarge } from './KeyTakeAway';
import { LyraTheme as theme } from 'src/components/theme/lyra/Theme';
import { FlexBoxRow } from 'src/components/common/FlexBoxRow';
import { useTranslation } from 'react-i18next';

import SharpenedLogoArrowDesktop from 'src/assets/websitegrader/footer/SharpenedLogoArrowDesktop.svg';
import SharpenedLogoArrowMobile from 'src/assets/websitegrader/footer/SharpenedLogoArrowMobile.svg';

export const Footer = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation(['websiteGrader']);

  return (
    <ResponsiveRow
      id="website-grader-footer"
      sx={{
        gap: isMobile ? 5 : 0,
        borderRadius: '12px',
        justifyContent: 'flex-start',
        background: 'rgba(218, 228, 255, 0.40)',
        alignSelf: 'stretch',
        p: isMobile ? '40px 16px 24px 16px' : 0,
      }}
    >
      <FlexBoxRow sx={{ flex: '1 0 30%', justifyContent: 'flex-start' }}>
        <img
          src={isMobile ? SharpenedLogoArrowMobile : SharpenedLogoArrowDesktop}
          width={isMobile ? '136px' : '336px'}
          alt="Sharpened-Arrow-Pointing-Up-Icon"
        />
      </FlexBoxRow>
      <FlexBoxColumn
        sx={{
          flex: '10 0 0',
          alignItems: 'flex-start',
          gap: 2,
          paddingY: isMobile ? 0 : 7,
          paddingX: isMobile ? 0 : 7,
        }}
      >
        <Typography variant="h5">{t('footer.localiqWebsites')}</Typography>
        <Typography variant="h3">{t('footer.weCanHelp')}</Typography>
        <BodyXLarge>{t('footer.helpDescription')}</BodyXLarge>
        <Button
          variant="contained"
          color="primary"
          sx={{
            fontSize: isMobile ? 14 : 20,
            borderRadius: 12,
            height: 40,
            padding: isMobile
              ? `${theme.spacing(1.25)} ${theme.spacing(2.5)}`
              : `${theme.spacing(2)} ${theme.spacing(3)}`,
            textTransform: 'initial',
          }}
        >
          {t('footer.letsTalk')}
        </Button>
      </FlexBoxColumn>
    </ResponsiveRow>
  );
};
