import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { Typography, Theme, Box, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BoldTypography } from 'src/components/common/styled/BoldTypography';
import { useShoppingGradeScore } from 'src/hooks/shopping/useShoppingGradeScrore';
import { GoogleShoppingScoreSection } from 'src/enums/google/ScoreSection';
import { ScoreName } from 'src/enums/ScoreName';
import { GradeUtils } from 'src/utils/GradeUtils';
import { ReactComponent as AlertIcon } from 'src/assets/AlertIconCurrent.svg';
import { FlexBoxRow } from 'src/components/common/FlexBoxRow';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import styled from 'styled-components';
import { GoogleAdType } from 'src/enums/GoogleAdType';

interface Props {
  sectionKey: string;
}

interface IconProps {
  scorename: ScoreName | undefined;
  theme: Theme;
}

const StyledAlertIcon = styled(AlertIcon)<{ scorename: ScoreName | undefined; theme: Theme }>`
  fill: ${({ scorename, theme }: IconProps) => (scorename ? GradeUtils.getScoreColor(scorename, theme) : 'none')};
`;

export const ShoppingKeyMetricInsight = ({ sectionKey }: Props): JSX.Element => {
  const { t } = useTranslation([GoogleAdType.SHOPPING]);
  const { scoreName, shoppingGrade } = useShoppingGradeScore({ sectionName: GoogleShoppingScoreSection.WASTED_SPEND });
  const isTabletScreen = useMediaQuery(OrionTheme.breakpoints.between('sm', 'md'));

  return sectionKey === GradeUtils.getSectionKey(GoogleShoppingScoreSection.ACCOUNT_STRUCTURE) ? (
    <FlexBoxColumn sx={{ alignItems: 'flex-start' }} gap={1}>
      <Typography fontWeight={'fontWeightMedium'} variant="h5">
        {t('grade.keyMetricTextTitle', { context: sectionKey })}
      </Typography>
      <Typography variant="body1">{t('grade.keyMetricText', { context: sectionKey })}</Typography>
    </FlexBoxColumn>
  ) : (
    <FlexBoxColumn gap={2}>
      <FlexBoxColumn gap={0.5}>
        <Box sx={{ minHeight: isTabletScreen ? '50px' : 'initial' }}>
          <BoldTypography textAlign={'center'} variant="h4">
            {t('grade.keyInsightScoreTitle', { context: sectionKey })}
          </BoldTypography>
        </Box>
        <Typography variant="overline" textTransform={'uppercase'} sx={{ fontWeight: 'fontWeightMedium' }}>
          {t('grade.keyInsightText', { context: 'last90Days' })}
        </Typography>
      </FlexBoxColumn>
      <FlexBoxRow gap={2}>
        <StyledAlertIcon scorename={scoreName} />
        {shoppingGrade && sectionKey === GradeUtils.getSectionKey(GoogleShoppingScoreSection.WASTED_SPEND) && (
          <Typography variant="h3" whiteSpace={'pre'}>
            {(shoppingGrade.negativeKeywords.wasteLocal / 4).toLocaleString(process.env.REACT_APP_LOCALE, {
              minimumFractionDigits: 2,
              style: 'currency',
              currency: shoppingGrade.negativeKeywords.currencyCode,
            })}
          </Typography>
        )}
        {shoppingGrade && sectionKey === GradeUtils.getSectionKey(GoogleShoppingScoreSection.CAMPAIGN_RANKINGS) && (
          <Typography variant="h3" whiteSpace={'pre'}>
            {shoppingGrade.shoppingPerformanceMetrics.ctr.toLocaleString(process.env.REACT_APP_LOCALE, {
              minimumFractionDigits: 2,
              style: 'percent',
            })}
          </Typography>
        )}
      </FlexBoxRow>
    </FlexBoxColumn>
  );
};
