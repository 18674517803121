import { ChooseAccount } from '../../components/googleAds/chooseAccount/ChooseAccount';
import { FormStepOne } from '../../components/googleAds/formStepOne/FormStepOne';
import { FormStepTwo } from '../../components/googleAds/formStepTwo/FormStepTwo';
import { Step } from '../../enums/Step';
import { useGGContext } from 'src/components/providers/googleAds';
import { GoogleAdsPage } from 'src/components/googleAds/page/Page';
import { useCallback, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { GridType } from 'src/enums/GridType';
import { Helmet } from 'react-helmet-async';
import useGATracking from 'src/hooks/google/useGATracking';
import { useFormPrefill } from 'src/hooks/google/useFormPrefill';
import { PageTitle } from 'src/enums/PageTitle';
import { useTranslation } from 'react-i18next';
import { IGGContext } from 'src/types/google/GGContext';
import BadgeFooter from 'src/components/common/BadgeFooter';
import { ServiceType } from 'src/enums/ServiceType';
import GooglePartnerSVG from 'src/assets/GooglePartner.svg';

const WrapTitle = (FormComponent: React.FC, context: IGGContext): JSX.Element => {
  return (
    <>
      <Helmet>
        <title>{`${PageTitle.GOOGLE} | ${context.currentStep}`}</title>
      </Helmet>
      <FormComponent />
    </>
  );
};

export const GoogleAdsAccountHome = (): JSX.Element => {
  const context = useGGContext();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { pushPageViewEvent } = useGATracking({});
  const prefillData = useFormPrefill(context);
  const { t } = useTranslation(['common']);

  useEffect(() => {
    if (!context.googleAdsAccount.accountId) {
      if (searchParams.get('auth')) {
        const authKey = searchParams.get('auth');
        context.setAuth(authKey);
        context.setCurrentStep(Step.CHOOSE_GOOGLE_ADS_ACCOUNT);
      } else {
        navigate('/');
      }
    }
  });

  useEffect(() => {
    if (context.currentStep === Step.CHOOSE_GOOGLE_ADS_ACCOUNT && context.auth) {
      pushPageViewEvent({ title: `GoogleAdsGrader ${context.currentStep}`, auth: context.auth });
    }
    if (context.currentStep === Step.FILL_BUSINESS_INFO) {
      context.setIsNewReport(true);
    }
  }, [context.currentStep]);

  useEffect(() => {
    if (prefillData.prefillData?.companies && prefillData.prefillData.companies[0]) {
      context.setUserDetails({
        firstName: prefillData.prefillData.first_name!,
        lastName: prefillData.prefillData.last_name!,
        phoneNumber: prefillData.prefillData.companies[0].phone_number!,
      });

      context.setBusinessDetails({
        businessName: prefillData.prefillData.companies[0].name!,
        categoryId: prefillData.prefillData.companies[0].business_category_id!,
        subcategoryId: prefillData.prefillData.companies[0].business_sub_category_id!,
      });
    }
  }, [prefillData.prefillData]);

  const renderSwitch = useCallback(() => {
    switch (context.currentStep) {
      case Step.CHOOSE_GOOGLE_ADS_ACCOUNT:
        return WrapTitle(ChooseAccount, context);
      case Step.FILL_YOUR_INFO:
        return WrapTitle(FormStepOne, context);
      case Step.FILL_BUSINESS_INFO:
        return WrapTitle(FormStepTwo, context);
      default:
        return <>{t('errors.500.title')}</>;
    }
  }, [context]);

  return (
    <GoogleAdsPage
      gridType={GridType.FORM}
      additionalFooter={
        <BadgeFooter i18nNameSpace={ServiceType.GOOGLE} badgeSVG={GooglePartnerSVG} iconHref={t('googleBadgeLink')} />
      }
    >
      {renderSwitch()}
    </GoogleAdsPage>
  );
};
