import { createContext, useContext, useState } from 'react';
import { AdGeneratorStep } from '../common/adGenerator/Stepper';
import { IAdGeneratorFormKeywords } from '../common/adGenerator/formKeywordStep/useSubmitKeywordsForm';
import { IAdGeneratorFormIndustry } from '../common/adGenerator/formIndustryStep/useSubmitIndustryForm';
import { IAdGeneratorFormStepThree } from '../common/adGenerator/formStepWebSite/useSubmitForm';
import { MonthlyDigitalAdvertisingBudget, TypeOfBusiness } from '../common/adGenerator/Result/modal/CreateMoreContent';
import { IAdGeneratorResponse } from '../common/adGenerator/AdGeneratorUtils';

export interface IAdGeneratorContext {
  currentStep: AdGeneratorStep;
  setCurrentStep: React.Dispatch<React.SetStateAction<AdGeneratorStep>>;
  industryDetails: IAdGeneratorFormIndustry;
  setIndustryDetails: React.Dispatch<React.SetStateAction<IAdGeneratorFormIndustry>>;
  businessDetails: IAdGeneratorFormKeywords;
  setBusinessDetails: React.Dispatch<React.SetStateAction<IAdGeneratorFormKeywords>>;
  contactDetails: IAdGeneratorFormStepThree;
  setContactDetails: React.Dispatch<React.SetStateAction<IAdGeneratorFormStepThree>>;
  result: IAdGeneratorResponse | undefined;
  setResult: React.Dispatch<React.SetStateAction<IAdGeneratorResponse | undefined>>;
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  sfdcCampaignId: string | null;
  setSfdcCampaignId: React.Dispatch<React.SetStateAction<string | null>>;
  userDetails: {
    firstName: string;
    lastName: string;
    phone: string;
    typeOfBusiness: TypeOfBusiness;
    monthlyDigitalAdvertisingBudget: MonthlyDigitalAdvertisingBudget;
    userId: number | undefined;
    infoCollected: boolean;
    requestCount: number;
    remainingCount: number;
  };
  setUserDetails: React.Dispatch<
    React.SetStateAction<{
      firstName: string;
      lastName: string;
      phone: string;
      typeOfBusiness: TypeOfBusiness;
      monthlyDigitalAdvertisingBudget: MonthlyDigitalAdvertisingBudget;
      userId: number | undefined;
      infoCollected: boolean;
      requestCount: number;
      remainingCount: number;
    }>
  >;
}

const AdGeneratorContext = createContext({} as IAdGeneratorContext);

const AdGeneratorContextProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [currentStep, setCurrentStep] = useState<AdGeneratorStep>(AdGeneratorStep.CONTENT_TO_BE_INCLUDED);
  const [industryDetails, setIndustryDetails] = useState<IAdGeneratorFormIndustry>({} as IAdGeneratorFormIndustry);
  const [businessDetails, setBusinessDetails] = useState<IAdGeneratorFormKeywords>({} as IAdGeneratorFormKeywords);
  const [contactDetails, setContactDetails] = useState<IAdGeneratorFormStepThree>({} as IAdGeneratorFormStepThree);
  const [result, setResult] = useState<IAdGeneratorResponse | undefined>(undefined);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [sfdcCampaignId, setSfdcCampaignId] = useState<string | null>(null);
  const [userDetails, setUserDetails] = useState<{
    firstName: string;
    lastName: string;
    phone: string;
    typeOfBusiness: TypeOfBusiness;
    monthlyDigitalAdvertisingBudget: MonthlyDigitalAdvertisingBudget;
    userId: number | undefined;
    infoCollected: boolean;
    requestCount: number;
    remainingCount: number;
  }>(
    {} as {
      firstName: string;
      lastName: string;
      phone: string;
      typeOfBusiness: TypeOfBusiness;
      monthlyDigitalAdvertisingBudget: MonthlyDigitalAdvertisingBudget;
      userId: number | undefined;
      infoCollected: boolean;
      requestCount: number;
      remainingCount: number;
    }
  );

  const value = {
    currentStep,
    setCurrentStep,
    industryDetails,
    setIndustryDetails,
    businessDetails,
    setBusinessDetails,
    contactDetails,
    setContactDetails,
    result,
    setResult,
    userDetails,
    setUserDetails,
    modalOpen,
    setModalOpen,
    sfdcCampaignId,
    setSfdcCampaignId,
  };

  return <AdGeneratorContext.Provider value={value}>{children}</AdGeneratorContext.Provider>;
};

export const useAdGeneratorContext = (): IAdGeneratorContext => useContext(AdGeneratorContext);

export const withAdGeneratorProvider = (component: JSX.Element): JSX.Element => (
  <AdGeneratorContextProvider>{component}</AdGeneratorContextProvider>
);
