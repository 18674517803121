import { yupResolver } from '@hookform/resolvers/yup';
import { useMediaQuery, useTheme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { KeywordsContext } from 'src/components/providers/keywords';
import { BusinessType } from 'src/enums/BusinessType';
import { IEmailKeywordResponse } from 'src/types/keywords/KeywordResponse';
import { useFreemiumAuth } from 'src/hooks/keywords/useFreemiumAuth';
import { Logger } from 'src/utils/Logger';
import * as Yup from 'yup';

type EmailResponseProps = {
  onResponse: (response: IEmailKeywordResponse) => void;
  onClose: () => void;
};

type FormData = {
  email: string;
  businessType: string;
  cid: string;
};

type FreemiumProps = {
  email: string;
  businessType: string;
  cid: string;
  uuid: string | undefined;
};

type ReturnType = {
  isMobile: boolean;
  onDownload: (data: FormData) => void;
  methods: UseFormReturn<
    {
      email: string;
      businessType: string;
      cid: string;
    },
    object
  >;
};

const useEmailForm = ({ onResponse, onClose }: EmailResponseProps): ReturnType => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { keywords } = useContext(KeywordsContext);
  const [modalResponse, setModalResponse] = useState<IEmailKeywordResponse>();
  const [freemiumProps, setFreemiumProps] = useState<FreemiumProps>({ email: '', businessType: '', cid: '', uuid: '' });
  useFreemiumAuth({
    email: freemiumProps.email,
    businessType: freemiumProps.businessType,
    cid: freemiumProps.cid,
    uuid: freemiumProps.uuid,
  });

  useEffect(() => {
    if (modalResponse) {
      onResponse(modalResponse);
    }
  }, [modalResponse]);

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email is invalid.'),
    businessType: Yup.string().required('Business Type is required').typeError('Business Type is required'),
  });

  const methods = useForm({
    defaultValues: {
      email: '',
      businessType: '',
      cid: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const onDownload = (data: FormData) => {
    /*
      Freemium path redirects to freemium authentication path & we are good to go.
      Otherwise we post data to backend & get email sent confirmation response
     */
    if (process.env.REACT_APP_FKT_FREEMIUM?.toLowerCase() === 'active') {
      setFreemiumProps({ email: data.email, businessType: data.businessType, cid: data.cid, uuid: keywords?.uuid });
      onClose();
    } else {
      const URL = `${process.env.REACT_APP_BACKEND_URL}/google/keywords/${keywords?.uuid}/email`;
      fetch(URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          emailRecipient: data.email,
          isClient: data.businessType === BusinessType.CLIENT,
          sfdcCampaignID: data.cid,
        }),
      })
        .then((res) => res.json())
        .then((emailResponse: IEmailKeywordResponse) => {
          Logger.trace('downloadButton:EmailKeywords', { emailResponse }, 'EmailKeywords');
          setModalResponse(emailResponse);
          onClose();
        });
    }
  };

  return { isMobile, onDownload, methods };
};

export default useEmailForm;
