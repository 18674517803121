import { LyraTheme as theme } from 'src/components/theme/lyra/Theme';
import { FlexBoxRow } from 'src/components/common/FlexBoxRow';
import { ScoreName } from 'src/enums/ScoreName';
import { BoxProps } from '@mui/material';
import { GradeUtils } from 'src/utils/GradeUtils';
import { IHeaderProps } from './Header';

import styled from 'styled-components';

import FoundationIcon from 'src/assets/websitegrader/gradebar/Foundation.svg';
import IntermediateIcon from 'src/assets/websitegrader/gradebar/Intermediate.svg';
import AdvancedIcon from 'src/assets/websitegrader/gradebar/Advanced.svg';
import IntermediateIconGrey from 'src/assets/websitegrader/gradebar/IntermediateGrey.svg';
import AdvancedIconGrey from 'src/assets/websitegrader/gradebar/AdvancedGrey.svg';

interface IHeaderBarStepContainerProps extends BoxProps {
  $isFoundation: boolean;
  $isIntermediate: boolean;
  $score: ScoreName;
}

const HeaderBarStepContainer = styled(FlexBoxRow).attrs<IHeaderBarStepContainerProps>((props) => ({ ...props }))(
  ({ $isFoundation, $isIntermediate, theme, $score }) => {
    let sx: object = { flex: '1 0 0', padding: theme.spacing(1) };
    if ($isFoundation) {
      sx = {
        ...sx,
        background:
          $score === ScoreName.FOUNDATIONAL
            ? theme.palette.score?.foundational
            : $score === ScoreName.INTERMEDIATE
            ? theme.palette.score?.intermediate
            : theme.palette.score?.advanced,
        borderTopLeftRadius: '12px',
        borderBottomLeftRadius: '12px',
        borderRight: `1px solid rgba(0, 0, 0, 0.15)`,
      };
    } else if ($isIntermediate) {
      sx = {
        ...sx,
        background:
          $score === ScoreName.INTERMEDIATE
            ? theme.palette.score?.intermediate
            : $score === ScoreName.ADVANCED
            ? theme.palette.score?.advanced
            : undefined,
        borderRight: `1px solid rgba(0, 0, 0, 0.15)`,
      };
    } else {
      sx = {
        ...sx,
        background: $score === ScoreName.ADVANCED ? theme.palette.score?.advanced : undefined,
        borderTopRightRadius: '12px',
        borderBottomRightRadius: '12px',
      };
    }
    return { ...sx };
  }
);

export const HeaderBar = ({ score }: Pick<IHeaderProps, 'score'>) => {
  const scoreName = GradeUtils.getScoreNameFromNumber(score);
  return (
    <FlexBoxRow
      sx={{
        alignSelf: 'stretch',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        height: theme.spacing(6),
        background: theme.palette.common.white,
        borderRadius: theme.spacing(1.5),
        boxShadow: `0px 4px 24px 0px rgba(21, 26, 41, 0.10)`,
      }}
    >
      <HeaderBarStepContainer $isFoundation={true} $isIntermediate={false} $score={scoreName}>
        <img src={FoundationIcon} height={32} width={32} alt="Foundational Score Icon" />
      </HeaderBarStepContainer>
      <HeaderBarStepContainer $isFoundation={false} $isIntermediate={true} $score={scoreName}>
        {scoreName === ScoreName.FOUNDATIONAL ? (
          <img src={IntermediateIconGrey} height={32} width={32} alt="Intermediate Score Grey Icon" />
        ) : (
          <img src={IntermediateIcon} height={32} width={32} alt="Intermediate Score Icon" />
        )}
      </HeaderBarStepContainer>
      <HeaderBarStepContainer $isFoundation={false} $isIntermediate={false} $score={scoreName}>
        {scoreName === ScoreName.ADVANCED ? (
          <img src={AdvancedIcon} height={32} width={32} alt="Advanced Score Icon" />
        ) : (
          <img src={AdvancedIconGrey} height={32} width={32} alt="Advanced Score Grey Icon" />
        )}
      </HeaderBarStepContainer>
    </FlexBoxRow>
  );
};
