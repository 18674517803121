import { useContext } from 'react';
import { BoldTypography } from 'src/components/common/styled/BoldTypography';
import { useTranslation } from 'react-i18next';
import {
  GoogleAdsScoreSection,
  GoogleDisplayScoreSection,
  GoogleScoreSectionType,
  GoogleShoppingScoreSection,
} from 'src/enums/google/ScoreSection';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { BoxProps, Divider, useMediaQuery } from '@mui/material';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import { GoogleAdType } from 'src/enums/GoogleAdType';
import { ResponsiveRow } from '../../ResponsiveRow';
import { DisplayKeyMetricInsight } from 'src/components/displayAds/grade/keyMetrics/Insight';
import { SearchKeyMetricInsight } from 'src/components/googleAds/grade/keyMetrics/Insight';
import { ShoppingKeyMetricInsight } from 'src/components/shopping/grade/keyMetrics/Insight';
import { GoogleAdTypeContext } from 'src/components/providers/adType';
import { DisplayKeyMetric } from 'src/components/displayAds/grade/keyMetrics/KeyMetric';
import { SearchKeyMetric } from 'src/components/googleAds/grade/keyMetrics/KeyMetric';
import { ShoppingKeyMetric } from 'src/components/shopping/grade/keyMetrics/KeyMetric';
import { GradeUtils } from 'src/utils/GradeUtils';
import { StyledCard } from 'src/components/common/styled/StyledCard';
import { OrionColors } from 'src/components/theme/orion/common/colors';
import { ScoreName } from 'src/enums/ScoreName';
import { useGradeSectionScore } from 'src/hooks/common/useGradeSectionScore';
import { ServiceContext } from 'src/components/providers/service';

type Props = {
  sectionName: GoogleScoreSectionType;
};

enum ContainerPosition {
  LEFT = 'LEFT',
  RGHT = 'RGHT',
}

type ContainerProps = Props & {
  scorename: ScoreName;
  isMobile: boolean;
  position: ContainerPosition;
  children: JSX.Element;
};

const Container = ({ sectionName, scorename, isMobile, position, children }: ContainerProps): JSX.Element => {
  const sx: BoxProps['sx'] = {
    alignSelf: 'stretch',
    flex:
      sectionName === GoogleAdsScoreSection.WASTED_SPEND || sectionName === GoogleShoppingScoreSection.CAMPAIGN_RANKINGS
        ? '1 0 0'
        : 'initial',
    background:
      sectionName === GoogleAdsScoreSection.WASTED_SPEND || sectionName === GoogleShoppingScoreSection.CAMPAIGN_RANKINGS
        ? OrionTheme.palette.common.white
        : position === ContainerPosition.LEFT
        ? scorename !== ScoreName.ADVANCED
          ? OrionTheme.palette.background.default
          : OrionColors.score.greenTint
        : OrionTheme.palette.common.white,
    padding: isMobile
      ? OrionTheme.spacing(3)
      : sectionName === GoogleAdsScoreSection.WASTED_SPEND ||
        sectionName === GoogleShoppingScoreSection.CAMPAIGN_RANKINGS
      ? OrionTheme.spacing(4)
      : OrionTheme.spacing(5),
  };
  return <FlexBoxColumn sx={{ ...sx }}>{children}</FlexBoxColumn>;
};

export const KeyMetrics = ({ sectionName }: Props): JSX.Element => {
  const [service] = useContext(ServiceContext);
  const { isDisplay, isShopping } = useContext(GoogleAdTypeContext);
  const sectionKey = GradeUtils.getSectionKey(sectionName);
  const { t } = useTranslation([GoogleAdType.DISPLAY]);
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down(600));
  const { scoreName } = useGradeSectionScore({ service, sectionName });

  return (
    <FlexBoxColumn
      sx={{ alignItems: isMobile ? 'flex-start' : 'stretch', gap: isMobile ? 2 : 3, alignSelf: 'stretch' }}
    >
      {/* <!-- Heading --> */}
      <BoldTypography variant={'h4'}>{t('grade.keyMetric')}</BoldTypography>
      {/* <!-- Content --> */}
      <StyledCard key={`${sectionName}-score-description`} sx={{ p: 0, alignSelf: 'stretch' }}>
        <ResponsiveRow
          breakpoint={600}
          sx={{ height: '100%', justifyContent: 'stretch', alignItems: 'stretch', alignSelf: 'stretch' }}
        >
          <Container
            sectionName={sectionName}
            isMobile={isMobile}
            position={ContainerPosition.LEFT}
            scorename={scoreName!}
          >
            {isDisplay ? (
              <DisplayKeyMetric sectionname={sectionName as GoogleDisplayScoreSection} sectionKey={sectionKey} />
            ) : isShopping ? (
              <ShoppingKeyMetric sectionname={sectionName as GoogleShoppingScoreSection} sectionKey={sectionKey} />
            ) : (
              <SearchKeyMetric sectionname={sectionName as GoogleAdsScoreSection} sectionKey={sectionKey} />
            )}
          </Container>
          <Divider
            orientation={isMobile ? 'horizontal' : 'vertical'}
            flexItem={true}
            sx={{ borderColor: OrionColors.gray.dark }}
          />
          <Container
            sectionName={sectionName}
            isMobile={isMobile}
            position={ContainerPosition.RGHT}
            scorename={scoreName!}
          >
            {isDisplay ? (
              <DisplayKeyMetricInsight sectionKey={sectionKey} />
            ) : isShopping ? (
              <ShoppingKeyMetricInsight sectionKey={sectionKey} />
            ) : (
              <SearchKeyMetricInsight sectionKey={sectionKey} />
            )}
          </Container>
        </ResponsiveRow>
      </StyledCard>
    </FlexBoxColumn>
  );
};
