import { WebsiteGraderNavBar } from 'src/components/common/websiteGrader/NavBar';
import { Helmet } from 'react-helmet-async';
import { Header } from 'src/components/common/websiteGrader/resultPage/header/Header';
import { HeaderBackground } from 'src/components/common/websiteGrader/resultPage/header/HeaderBackground';
import { Competitor } from 'src/components/common/websiteGrader/resultPage/header/Competitor';
import { ErrorFlexBoxColumn } from 'src/components/common/websiteGrader/Error';
import { ContainerForLoadingAndErrorComponents } from 'src/components/common/websiteGrader/ContainerForLoadingAndErrorComponents';
import { TabsWrapper } from 'src/components/common/styled/TabsWrapper';
import { Tab } from 'src/components/common/styled/Tab';
import { BoxProps, Tabs, Typography, useMediaQuery } from '@mui/material';
import { TabLabel } from 'src/components/common/websiteGrader/resultPage/tab/Label';
import { KeyTakeAway } from 'src/components/common/websiteGrader/resultPage/tab/KeyTakeAway';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { AccordionCard } from 'src/components/common/websiteGrader/resultPage/tab/AccordionCard';
import { FlexBoxRow } from 'src/components/common/FlexBoxRow';
import { useReport } from './useReport';
import { groupMetrics } from './groupMetrics';
import { LyraTheme as theme } from 'src/components/theme/lyra/Theme';
import { MetricSelector } from 'src/components/common/websiteGrader/resultPage/tab/MetricSelector';
import { Footer } from 'src/components/common/websiteGrader/resultPage/tab/Footer';
import { TFunction, useTranslation } from 'react-i18next';

import styled from 'styled-components';

export enum OptimizationLevel {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export enum WebsiteBuildAttribute {
  DOMAIN_AGE = 'domainAge',
  SSL = 'ssl',
  MOBILE = 'mobile',
  TABLET = 'tablet',
  ALTERNATIVE_TEXT = 'alternativeText',
  AMOUNT_OF_CONTENT = 'amountOfContent',
  BACK_LINKS = 'backlinks',
  BROKEN_LINKS = 'brokenLinks',
  CODE_QUALITY = 'codeQuality',
  HEADINGS = 'headings',
  IMAGES = 'images',
  PAGE_TITLES_AND_DESCRIPTION = 'pageTitlesAndDescriptions',
  SITEMAP = 'sitemap',
}

export enum SocialAndLocalPresenceAttribute {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  X = 'twitter',
}

export enum DigitalAdvertisingAttribute {
  GOOGLE_ADS = 'paidSearch',
  DISPLAY_ADS = 'displayAds',
  RETARGETING = 'retargeting',
}

export enum WebsiteScoreSubCategory {
  WEBSITE_BUILD = 'websiteBuild',
  SOCIAL_AND_LOCAL_PRESENCE = 'social',
  DIGITAL_ADVERTISING = 'advertising',
}

export interface IWebsiteGrade {
  uuid: string;
  overallScore: number;
  domain: string;
  runDate: string;
  status: string;
  errorMessage: string | null;
  competitors: {
    competitors?: (Omit<IWebsiteGrade, 'competitors'> & { competitorId: number })[];
  };
  subCategories: {
    [key in WebsiteScoreSubCategory]: {
      name: WebsiteScoreSubCategory;
      subScore: number;
    };
  };
  scoreDetails: {
    [key in WebsiteBuildAttribute | SocialAndLocalPresenceAttribute | DigitalAdvertisingAttribute]: {
      optimizationLevel: keyof typeof OptimizationLevel;
    };
  };
}

const MobileContent = ({
  grade,
  metrics,
  selectedtab,
  t,
}: {
  grade: IWebsiteGrade;
  metrics: {
    lowMetrics: (WebsiteBuildAttribute | SocialAndLocalPresenceAttribute | DigitalAdvertisingAttribute)[];
    mediumMetrics: (WebsiteBuildAttribute | SocialAndLocalPresenceAttribute | DigitalAdvertisingAttribute)[];
    highMetrics: (WebsiteBuildAttribute | SocialAndLocalPresenceAttribute | DigitalAdvertisingAttribute)[];
  };
  selectedtab: WebsiteScoreSubCategory;
  t: TFunction;
}) => (
  <FlexBoxColumn sx={{ gap: 5, mt: 5, pb: 2 }}>
    <KeyTakeAway $sectionScore={grade.subCategories[selectedtab].subScore} sectionName={selectedtab} />
    {Object.values(OptimizationLevel).map(
      (metricType: OptimizationLevel) =>
        metrics[`${metricType}Metrics` as 'lowMetrics' | 'mediumMetrics' | 'highMetrics'].length > 0 && (
          <FlexBoxColumn sx={{ gap: 2, alignSelf: 'stretch', alignItems: 'flex-start' }} key={metricType}>
            <FlexBoxColumn sx={{ alignItems: 'flex-start' }}>
              <Typography variant="h3">{t(`score.actions.heading_${metricType}`)}</Typography>
            </FlexBoxColumn>
            <FlexBoxColumn sx={{ flex: '1 0 0', gap: 2 }}>
              {metrics[`${metricType}Metrics` as 'lowMetrics' | 'mediumMetrics' | 'highMetrics'].map((metricItem) => (
                <AccordionCard sectionattrname={metricItem} optimizationLevel={metricType} key={metricItem} />
              ))}
            </FlexBoxColumn>
          </FlexBoxColumn>
        )
    )}
    <Footer />
  </FlexBoxColumn>
);

const DesktopTabPanel = ({
  grade,
  metrics,
  selectedtab,
  t,
}: {
  grade: IWebsiteGrade;
  metrics: {
    lowMetrics: (WebsiteBuildAttribute | SocialAndLocalPresenceAttribute | DigitalAdvertisingAttribute)[];
    mediumMetrics: (WebsiteBuildAttribute | SocialAndLocalPresenceAttribute | DigitalAdvertisingAttribute)[];
    highMetrics: (WebsiteBuildAttribute | SocialAndLocalPresenceAttribute | DigitalAdvertisingAttribute)[];
  };
  selectedtab: WebsiteScoreSubCategory;
  t: TFunction;
}) => (
  <FlexBoxColumn sx={{ gap: 10, mt: 10 }}>
    <KeyTakeAway $sectionScore={grade.subCategories[selectedtab].subScore} sectionName={selectedtab} />
    <FlexBoxColumn sx={{ gap: 5, alignSelf: 'stretch' }}>
      {Object.values(OptimizationLevel).map(
        (metricType: OptimizationLevel) =>
          metrics[`${metricType}Metrics` as 'lowMetrics' | 'mediumMetrics' | 'highMetrics'].length > 0 && (
            <FlexBoxColumn sx={{ alignSelf: 'stretch' }} key={metricType}>
              <ColoredFlexBoxRow optimizationlevel={metricType}>
                <FlexBoxColumn sx={{ flex: '1 0 30%', alignItems: 'flex-start' }}>
                  <Typography variant="h3">{t(`score.actions.heading_${metricType}`)}</Typography>
                </FlexBoxColumn>
                <FlexBoxColumn sx={{ flex: '10 0 0', gap: 2 }}>
                  {metrics[`${metricType}Metrics` as 'lowMetrics' | 'mediumMetrics' | 'highMetrics'].map(
                    (metricItem) => (
                      <AccordionCard sectionattrname={metricItem} optimizationLevel={metricType} key={metricItem} />
                    )
                  )}
                </FlexBoxColumn>
              </ColoredFlexBoxRow>
            </FlexBoxColumn>
          )
      )}
    </FlexBoxColumn>
    <Footer />
  </FlexBoxColumn>
);

const ColoredFlexBoxRow = styled(FlexBoxRow).attrs<BoxProps & { optimizationlevel: OptimizationLevel }>((props) => ({
  ...props,
}))(({ optimizationlevel, theme }) => ({
  borderRadius: theme.spacing(1.5),
  alignSelf: 'stretch',
  padding: '24px 1px 1px 24px',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  gap: 5,
  background:
    optimizationlevel === 'low'
      ? `radial-gradient(137.2% 102.42% at 27.86% 0%, rgba(229, 50, 89, 0.14) 0%, rgba(229, 50, 89, 0.00) 75%), #FFF`
      : optimizationlevel === 'medium'
      ? `radial-gradient(129.59% 102.17% at 29.08% 0%, rgba(247, 198, 49, 0.22) 0%, rgba(247, 198, 49, 0.00) 75%), #FFF`
      : `radial-gradient(129.59% 102.17% at 29.08% 0%, rgba(17, 171, 125, 0.17) 0%, rgba(17, 171, 125, 0.00) 75%), #FFF`,
}));

export const WebsiteGraderReport = ({ grade }: { grade: IWebsiteGrade }) => {
  const { competitorScore, selectedTab, setSelectedTab, processing, error } = useReport({ grade });
  const sections = Object.values(WebsiteScoreSubCategory).map((sectionName) => sectionName);
  const groupedMetrics = groupMetrics({ grade, selected: selectedTab });
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation(['websiteGrader']);
  return (
    <>
      <Helmet>
        {/*  */}
        <style>
          {`
            body {
              background-color: white;
            }
          `}
        </style>
        <link rel="icon" href="/favicon-liq.ico" />
        <meta name="LocaliQ | Website Grader" content="LocaliQ | Website Grader" />
        <link rel="apple-touch-icon" href="/apple-touch-icon-liq.png" />
        <link rel="manifest" href="/manifest-liq.json" />
        <title>LocaliQ | Website Grader</title>
      </Helmet>
      {grade.errorMessage ?? error ? (
        <ContainerForLoadingAndErrorComponents $aiTool={false}>
          <ErrorFlexBoxColumn heading={'Sorry, something went wrong'} text={grade.errorMessage ?? error?.message} />
        </ContainerForLoadingAndErrorComponents>
      ) : (
        <>
          <HeaderBackground $score={grade.overallScore}>
            <>
              <WebsiteGraderNavBar />
              <Header
                uuid={grade.uuid}
                score={grade.overallScore}
                date={grade.runDate}
                competitorScore={competitorScore ?? undefined}
              />
              {competitorScore ? <></> : <Competitor uuid={grade.uuid} $running={processing} />}
            </>
          </HeaderBackground>
          {isMobile ? (
            <FlexBoxColumn sx={{ paddingX: 2 }}>
              <MetricSelector
                grade={grade}
                sections={sections}
                selectedsection={selectedTab}
                onChange={(newValue) => setSelectedTab(newValue)}
              />
              <MobileContent t={t} grade={grade} metrics={groupedMetrics} selectedtab={selectedTab} />
            </FlexBoxColumn>
          ) : (
            <TabsWrapper sx={{ p: '24px 40px 40px 40px' }}>
              <Tabs value={selectedTab} variant="fullWidth" onChange={(event, newValue) => setSelectedTab(newValue)}>
                {sections.map((sectionName) => (
                  <Tab
                    value={sectionName}
                    key={sectionName}
                    label={<TabLabel grade={grade} sectionName={sectionName} $selected={selectedTab === sectionName} />}
                  />
                ))}
              </Tabs>
              <div role="tab-panel">
                <DesktopTabPanel t={t} grade={grade} metrics={groupedMetrics} selectedtab={selectedTab} />
              </div>
            </TabsWrapper>
          )}
        </>
      )}
    </>
  );
};
