import { useEffect, useState } from 'react';
import { ScoreName } from 'src/enums/ScoreName';

type Props = {
  scorename: string | undefined;
};

type ReturnType = {
  left: string | undefined;
};

const SECTION_WIDTH_PERCENT = 33.33; // 1/3
const DEFAULT_LEFT_POSITION = '24px';
const HALF_OF_POPOVER_WIDTH = '162px'; // Approx
const HALF_OF_LOGO_WIDTH = '14px'; // Approx

export const useLeftPosition = ({ scorename }: Props): ReturnType => {
  const [left, setPixel] = useState<string>();

  useEffect(() => {
    switch (scorename) {
      case ScoreName.FOUNDATIONAL:
        setPixel(DEFAULT_LEFT_POSITION);
        break;
      case ScoreName.INTERMEDIATE:
        setPixel(
          `calc(${
            SECTION_WIDTH_PERCENT + SECTION_WIDTH_PERCENT / 2
          }% - ${HALF_OF_POPOVER_WIDTH} - ${HALF_OF_LOGO_WIDTH})`
        );
        break;
      case ScoreName.ADVANCED:
        setPixel(
          `calc(${
            SECTION_WIDTH_PERCENT * 2 + SECTION_WIDTH_PERCENT / 2
          }% - ${HALF_OF_POPOVER_WIDTH} - ${HALF_OF_LOGO_WIDTH})`
        );
        break;
      default:
        setPixel(DEFAULT_LEFT_POSITION);
    }
  }, [scorename]);

  return { left };
};
