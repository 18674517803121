import { useCallback, useState } from 'react';
import { UseFormGetValues } from 'react-hook-form';
import { Logger } from 'src/utils/Logger';
import { WebsiteGraderAddCompetitor } from '../../Utils';
import { PollCompetitorGrade } from '../../PollWebsiteGrade';
import { useWebsiteGraderContext } from 'src/components/providers/websiteGrader';

type ReturnType = {
  disabled: boolean;
  processing: boolean;
  onSubmit: () => void;
};

export const useSubmitCompetitorForm = (getValues: UseFormGetValues<{ website: string; uuid: string }>): ReturnType => {
  const [disabled, setDisabled] = useState(false);
  const [processing, setProcessing] = useState(false);
  const context = useWebsiteGraderContext();

  const onSubmit = useCallback(async () => {
    // set states
    setDisabled(true);
    setProcessing(true);
    //
    Logger.trace(
      'onSubmit:competitor:before',
      { website: getValues('website'), uuid: getValues('uuid') },
      'WebsiteGraderCompetitorForm'
    );
    // post competitor
    const response: { id: number } = await WebsiteGraderAddCompetitor({
      uuid: getValues('uuid'),
      website: getValues('website'),
    });
    Logger.trace(
      'onSubmit:competitor:before:competitor-grade-poll',
      { website: getValues('website'), response },
      'WebsiteGraderCompetitorForm'
    );
    const competitorGrade = await PollCompetitorGrade(getValues('uuid'), response.id);
    Logger.trace(
      'onSubmit:competitor:after:competitor-grade-poll',
      { website: getValues('website'), response, competitorGrade },
      'WebsiteGraderCompetitorForm'
    );
    setProcessing(false);
    context.setCompetitorScore({ ...competitorGrade });
  }, []);

  return { disabled, processing, onSubmit };
};
