import { Button } from '@mui/material';
import styled from 'styled-components';

export const StyledSignInButton = styled(Button)`
  height: 60px;
  width: 303px;
  ${({ theme }) => theme.breakpoints.down(460)} {
    width: 240px;
  }
  cursor: pointer;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  border: ${({ theme }) => `1px solid ${theme.palette.facebook.main}`};
  border-radius: 2px;
  background: ${({ theme }) => theme.palette.facebook.main};
`;
