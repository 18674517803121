import { createTheme, selectClasses } from '@mui/material';
import { LyraTypography } from './Typography';

declare module '@mui/material/styles' {
  interface Palette {
    score?: {
      foundational: string;
      intermediate: string;
      advanced: string;
    };
  }

  interface PaletteOptions {
    score?: {
      foundational: string;
      intermediate: string;
      advanced: string;
    };
  }
}

export const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
};

const rawTheme = createTheme({
  breakpoints: { values: { ...breakpoints.values } },
});

export const LyraTheme = createTheme({
  typography: { ...LyraTypography(rawTheme) },
  palette: {
    primary: { main: '#0046FE', light: '#DAE4FF' },
    grey: {
      50: '#F2F3F5', // extra light gray
      100: '#D7DAE0', // light gray
      400: '#A1A5B2', // neutral gray
      500: '#5E6373', // mid gray
      900: '#1E2129', // dark gray
    },
    common: {
      black: '#000000',
      white: '#FFFFFF',
    },
    success: {
      main: '#009E84',
    },
    error: {
      main: '#D7004B',
    },
    info: {
      main: '#7826EC',
    },
    warning: {
      main: '#FA6323',
    },
    background: {
      default: 'white',
      paper: 'white',
    },
    action: {
      active: '#0046FE',
      hover: '#0046FE',
      hoverOpacity: 0.12,
      selected: '#0046FE',
      selectedOpacity: 0.16,
      // disabled: 'string',
      // disabledOpacity: 0,
      // disabledBackground: 'string',
      focus: '#0046FE',
      focusOpacity: 0.16,
      activatedOpacity: 0.2,
    },
    score: {
      foundational: '#E53259',
      intermediate: '#F7C631',
      advanced: '#11AB7D',
    },
  },
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          width: '100%',
          height: rawTheme.spacing(2.5),
          borderRadius: rawTheme.spacing(2),
          background: '#AEAEAD',
        },
        bar: {
          background: 'none',
          borderRight: `${rawTheme.spacing(0.125)} solid ${rawTheme.palette.common.white}`,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #00000026',
          '.MuiTabs-flexContainer': {
            justifyContent: 'space-between',
          },
          ['.MuiTabs-indicator']: {
            height: '4px',
            backgroundColor: '#1E2129',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          opacity: 1,
          color: '#1E2129',
          textAlign: 'left',
          padding: 0,
          paddingBottom: rawTheme.spacing(2),
          ['&.Mui-selected']: {
            fontWeight: 600,
            color: '#1E2129',
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: rawTheme.spacing(1.5),
          border: `${rawTheme.spacing(0.125)} solid rgba(0, 0, 0, 0.15)}`,
          boxShadow: 'none',
          '&.MuiPaper-root:first-of-type': {
            borderRadius: '12px',
          },
          '&.MuiPaper-root:last-of-type': {
            borderRadius: '12px',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: { minHeight: 'auto' },
        content: ({ ownerState }) => {
          return {
            '&.Mui-expanded': {
              margin: 0,
            },
            flexDirection: 'column',
            margin: '0',
            padding: '8px 0',
            borderBottom: ownerState.expanded ? '1px solid #D7DAE0' : undefined,
          };
        },
        expandIconWrapper: {
          color: '#000000',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: { padding: '24px 16px' },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          padding: '8px 16px 8px 8px',
          borderRadius: rawTheme.spacing(1.5),
          background: rawTheme.palette.common.white,
          '.MuiOutlinedInput-notchedOutline': { borderColor: rawTheme.palette.grey[900] },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: rawTheme.palette.grey[900],
          },
        },
        iconOutlined: {
          right: rawTheme.spacing(2),
        },
        outlined: {
          textOverflow: 'ellipsis',
          [`&.${selectClasses.outlined}`]: {
            minHeight: 'auto',
            padding: 0,
            paddingRight: 0,
          },
        },
      },
    },
  },
});
