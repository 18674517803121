import { useFormContext, Controller } from 'react-hook-form';
import { InputProps, OutlinedInput, Typography } from '@mui/material';
import { memo, useRef } from 'react';
import { FlexBoxRow } from '../../FlexBoxRow';

import ErrorIcon from 'src/assets/ErrorIcon.svg';
import ErrorIconWhite from 'src/assets/ErrorIconWhite.svg';

export interface Props extends InputProps {
  name: string;
  size?: 'small' | 'medium';
  errorcolor?: 'common.white' | 'error';
}

export const FormInputText = memo(function FormInputText(props: Props): JSX.Element {
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const inputRef = useRef<Element>();
  const errorMessage = errors[props.name] ? (errors[props.name]!.message as unknown as string) : '';

  return (
    <Controller
      control={control}
      name={props.name}
      render={({ field: { onChange, value } }) => (
        <>
          <OutlinedInput
            {...props}
            aria-label={props.name}
            value={value}
            onChange={onChange}
            ref={inputRef}
            fullWidth={true}
            error={!!errors[props.name]}
            size={props.size ?? 'medium'}
            notched={false}
          />
          {errors[props.name] && props.style?.display !== 'none' && (
            <FlexBoxRow sx={{ justifyContent: 'flex-start', width: '100%', pt: 0.5, gap: 0.25 }}>
              <img
                src={props.errorcolor === 'common.white' ? ErrorIconWhite : ErrorIcon}
                alt="ErrorIcon"
                color={props.errorcolor ?? 'error'}
                width={18}
                height={18}
              />
              <Typography variant="caption" color={props.errorcolor ?? 'error'}>
                {errorMessage}
              </Typography>
            </FlexBoxRow>
          )}
        </>
      )}
    />
  );
});
