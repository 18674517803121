import { useTranslation } from 'react-i18next';
import { FlexBoxRow } from 'src/components/common/FlexBoxRow';
import { styled, Typography, useMediaQuery } from '@mui/material';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import { LineHeight } from 'src/components/theme/orion/overrides/LineHeight';
import { ResponsiveRow } from 'src/components/common/ResponsiveRow';
import { OrionColors } from 'src/components/theme/orion/common/colors';
import { GoogleShoppingScoreSection, ScoreSectionType } from 'src/enums/google/ScoreSection';
import { GradeUtils } from 'src/utils/GradeUtils';

const Label = styled(FlexBoxRow)`
  &:before {
    content: '';
    display: inline-block;
    min-width: ${({ theme }) => theme.spacing(2)};
    min-height: ${({ theme }) => theme.spacing(2)};
    border-radius: 50%;
    background: ${({ bcolor }: { bcolor: string }) => bcolor};
  }
`;

const GetGap = (isMobile: boolean, responsive: boolean): number => {
  if (isMobile) return 3;
  return responsive ? 0.5 : 3;
};

export const ChartLabel: React.FC<{ bcolor: string; children: JSX.Element }> = ({ bcolor, children }) => (
  <Label bcolor={bcolor}>{children}</Label>
);

type CommonChartLabelsProps = {
  responsive: boolean;
};

export const CommonChartLabels = ({ responsive }: CommonChartLabelsProps): JSX.Element => {
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down(600));
  const { t } = useTranslation(['common']);

  return (
    <ResponsiveRow
      breakpoint={responsive ? 'sm' : undefined}
      gap={GetGap(isMobile, responsive)}
      sx={{
        alignSelf: 'stretch',
        alignItems: isMobile ? 'flex-start' : 'center',
        mt: -1,
        pl: isMobile ? 9 : 0,
      }}
    >
      <ChartLabel bcolor={OrionColors.chart.orange}>
        <Typography sx={{ pl: 1 }} fontSize={isMobile ? '0.75rem' : '1rem'} lineHeight={LineHeight.LOOSE}>
          {t('chartLabels.you_common')}
        </Typography>
      </ChartLabel>
      <ChartLabel bcolor={OrionColors.chart.blue}>
        <Typography sx={{ pl: 1 }} fontSize={isMobile ? '0.75rem' : '1rem'} lineHeight={LineHeight.LOOSE}>
          {t('chartLabels.category_common')}
        </Typography>
      </ChartLabel>
    </ResponsiveRow>
  );
};

type ShoppingChartLabelsProps = {
  responsive: boolean;
  section?: ScoreSectionType;
};

export const ShoppingChartLabels = ({ responsive, section }: ShoppingChartLabelsProps): JSX.Element => {
  const qualifier =
    section === GoogleShoppingScoreSection.CAMPAIGN_RANKINGS ? GradeUtils.getSectionKey(section) : 'common';
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down(600));
  const { t } = useTranslation(['shopping']);

  return (
    <ResponsiveRow
      breakpoint={responsive ? 'sm' : undefined}
      gap={GetGap(isMobile, responsive)}
      sx={{
        alignSelf: 'stretch',
        alignItems: isMobile ? 'flex-start' : 'center',
        mt: -1,
        pl: isMobile ? 9 : 0,
      }}
    >
      <ChartLabel bcolor={OrionColors.chart.orange}>
        <Typography sx={{ pl: 1 }} fontSize={isMobile ? '0.75rem' : '1rem'} lineHeight={LineHeight.LOOSE}>
          {t(`chartLabels.you_${qualifier}`)}
        </Typography>
      </ChartLabel>
      <ChartLabel bcolor={OrionColors.chart.blue}>
        <Typography sx={{ pl: 1 }} fontSize={isMobile ? '0.75rem' : '1rem'} lineHeight={LineHeight.LOOSE}>
          {t(`chartLabels.category_${qualifier}`)}
        </Typography>
      </ChartLabel>
    </ResponsiveRow>
  );
};
