import { styled, Typography, Link, Divider, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ResponsiveRow } from '../ResponsiveRow';
import { FlexBoxColumn } from '../FlexBoxColumn';
import { FlexBoxRow } from '../FlexBoxRow';

import facebook from 'src/assets/adgenerator/platformicons/facebookIcon.svg';
import instagram from 'src/assets/adgenerator/platformicons/instagramicon.svg';
import linkedIn from 'src/assets/adgenerator/platformicons/linkedinicon.svg';
import twitter from 'src/assets/adgenerator/platformicons/xicon.svg';

const ColoredLink = styled(Link)`
  line-height: 24px;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
`;

const IconLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
`;

export const Footer = (): JSX.Element => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation(['adGenerator', 'common']);

  return (
    <FlexBoxRow
      sx={{
        alignSelf: 'stretch',
        alignItems: 'stretch',
        color: 'grey.100',
        backgroundColor: 'grey.500',
        paddingX: 5,
        paddingY: 3,
      }}
    >
      <ResponsiveRow
        breakpoint={'sm'}
        sx={{
          alignItems: isMobile ? 'center' : 'flex-end',
          justifySelf: 'stretch',
          justifyContent: 'space-between',
          alignSelf: 'stretch',
          width: isMobile ? 'initial' : '100%',
          gap: 2,
        }}
      >
        <FlexBoxColumn sx={{ justifyContent: 'center', alignItems: isMobile ? 'center' : 'flex-start', gap: 0.5 }}>
          <Typography variant="body2" color={'grey.100'} lineHeight={'24px'}>
            {t('footer.copyright')}
          </Typography>
          <ResponsiveRow
            breakpoint={'md'}
            gap={isMobileOrTablet ? 0 : 1}
            sx={{ alignItems: isMobileOrTablet ? 'center' : 'flex-start' }}
          >
            <FlexBoxRow gap={2} justifyContent="flex-start">
              <ColoredLink
                variant="body2"
                href={t('footer.link_cookies', { ns: 'adGenerator' })}
                target="_blank"
                color={'grey.100'}
              >
                {t('cookie', { ns: 'common' })}
              </ColoredLink>
              <Divider orientation={'vertical'} flexItem={true} sx={{ borderColor: 'grey.400', my: 0.25 }} />
              <ColoredLink
                variant="body2"
                href={t('footer.link_terms', { ns: 'adGenerator' })}
                target="_blank"
                color={'grey.100'}
              >
                {t('terms', { ns: 'adGenerator' })}
              </ColoredLink>
              {isMobileOrTablet ? (
                <></>
              ) : (
                <Divider orientation={'vertical'} flexItem={true} sx={{ borderColor: 'grey.400', my: 0.25 }} />
              )}
            </FlexBoxRow>
            <FlexBoxRow gap={2} justifyContent="flex-start" sx={{ pl: isMobileOrTablet ? 0 : 0.25 }}>
              <ColoredLink
                variant="body2"
                href={t('footer.link_security', { ns: 'adGenerator' })}
                target="_blank"
                color={'grey.100'}
              >
                {t('security', { ns: 'common' })}
              </ColoredLink>
              <Divider orientation={'vertical'} flexItem={true} sx={{ borderColor: 'grey.400', my: 0.25 }} />
              <ColoredLink
                variant="body2"
                href={t('footer.link_privacy', { ns: 'adGenerator' })}
                target="_blank"
                color={'grey.100'}
              >
                {t('privacy', { ns: 'common' })}
              </ColoredLink>
              {isMobileOrTablet ? (
                <></>
              ) : (
                <Divider orientation={'vertical'} flexItem={true} sx={{ borderColor: 'grey.400', my: 0.25 }} />
              )}
            </FlexBoxRow>
          </ResponsiveRow>
        </FlexBoxColumn>
        <FlexBoxRow gap={1.25}>
          <IconLink
            href={t('footer.social.facebook', { ns: 'adGenerator' })}
            target="_blank"
            sx={{ height: theme.spacing(5.25) }}
          >
            <img src={facebook} id="logo" alt="WordStream By LocaliQ on Facebook" height={42} width={42} />
          </IconLink>
          <IconLink
            href={t('footer.social.twitter', { ns: 'adGenerator' })}
            target="_blank"
            sx={{ height: theme.spacing(5.25) }}
          >
            <img src={twitter} id="logo" alt="WordStream By LocaliQ on Twitter" height={42} width={42} />
          </IconLink>
          <IconLink
            href={t('footer.social.linkedin', { ns: 'adGenerator' })}
            target="_blank"
            sx={{ height: theme.spacing(5.25) }}
          >
            <img src={linkedIn} id="logo" alt="WordStream By LocaliQ on LinkedIn" height={42} width={42} />
          </IconLink>
          <IconLink
            href={t('footer.social.instagram', { ns: 'adGenerator' })}
            target="_blank"
            sx={{ height: theme.spacing(5.25) }}
          >
            <img src={instagram} id="logo" alt="WordStream By LocaliQ on Instagram" height={42} width={42} />
          </IconLink>
        </FlexBoxRow>
      </ResponsiveRow>
    </FlexBoxRow>
  );
};
