import { LyraTheme as theme } from 'src/components/theme/lyra/Theme';
import { FlexBoxColumn } from '../../../FlexBoxColumn';
import { HeaderBar } from './HeaderBar';
import { HeaderScoreBubble } from './HeaderScoreBubble';
import { useMediaQuery } from '@mui/material';
import { CompetitorScoreBubble } from './CompetitorScoreBubble';
import { IWebsiteGrade } from 'src/pages/websiteGrader/Report';

export interface IHeaderProps {
  uuid: string;
  score: number;
  date: string;
  competitorScore: (Omit<IWebsiteGrade, 'competitors'> & { competitorId: number }) | undefined;
}

export const Header = ({ uuid, score, date, competitorScore }: IHeaderProps) => {
  const isLarge = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <FlexBoxColumn sx={{ alignSelf: 'stretch', paddingX: isLarge ? theme.spacing(22.5 - 2) : 0, gap: 3 }}>
      <HeaderScoreBubble score={score} date={date} />
      <HeaderBar score={score} />
      {competitorScore && <CompetitorScoreBubble uuid={uuid} competitorScore={competitorScore} />}
    </FlexBoxColumn>
  );
};
