import { IWebsiteGraderBuinsessDetails, IWebsiteGraderUserDetails } from 'src/components/providers/websiteGrader';

export const WebsiteGraderProcessScore = async (
  payload: IWebsiteGraderUserDetails & IWebsiteGraderBuinsessDetails & { recaptcha: string }
) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/website/process`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Recaptcha: payload.recaptcha,
    },
    body: JSON.stringify({
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email,
      websiteUrl: payload.website,
      businessName: payload.businessName,
    }),
  });

  if (response.ok) {
    return response.json();
  } else {
    const error = await response.json();
    throw error;
  }
};

export const WebsiteGraderAddCompetitor = async (payload: { uuid: string; website: string }) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/website/scores/${payload.uuid}/competitors`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ websiteUrl: payload.website }),
  });

  if (response.ok) {
    return response.json();
  } else {
    const error = await response.json();
    throw error;
  }
};

export const DeleteCompetitor = async (payload: { uuid: string; competitorId: number }) => {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/website/scores/${payload.uuid}/competitors/${payload.competitorId}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  if (response.ok) {
    return response.text();
  } else {
    const error = await response.json();
    throw error;
  }
};
