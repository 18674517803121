import { styled, Typography } from '@mui/material';
import { ResponsiveRow } from 'src/components/common/ResponsiveRow';
import { useTranslation } from 'react-i18next';
import LiQIcon from 'src/assets/liq-brand-icon.svg';

const Link = styled('a')(() => ({
  cursor: 'pointer',
}));

export const SubFooter = (): JSX.Element => {
  const { t } = useTranslation(['adGenerator']);

  return (
    <ResponsiveRow
      breakpoint={'lg'}
      sx={{
        paddingY: 5,
        paddingX: 6,
        backgroundColor: 'grey.50',
        alignSelf: 'stretch',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        gap: 3,
      }}
    >
      <Link href="https://www.localiq.com/" target="_blank" tabIndex={-1} aria-label="LOCALiQ" sx={{ height: '36px' }}>
        <img src={LiQIcon} id="logo" alt="LOCALiQ" height={36} />
      </Link>
      <Typography sx={{ maxWidth: 1064, color: 'grey.500', lineHeight: '25px', textAlign: 'left' }}>
        {t('subfooter.text')}
      </Typography>
    </ResponsiveRow>
  );
};
