import { IGGContext } from '../../types/google/GGContext';
import { createContext, useState, useContext, useMemo } from 'react';
import { IUserDetails } from 'src/types/common/UserDetails';
import { DataServiceProvider } from './service';
import { ServiceType } from 'src/enums/ServiceType';
import { IAccount } from 'src/types/common/Account';
import { GoogleAdType } from 'src/enums/GoogleAdType';
import { GoogleAdTypeProvider } from './adType';
import { IBusinessDetails } from 'src/types/common/BusinessDetails';
import { Step as GoogleStep } from 'src/enums/Step';

const GoogleGraderContext = createContext<IGGContext>({} as IGGContext);

type Props = {
  children: React.ReactNode;
};

export const GoogleGraderContextProvider = ({ children }: Props): JSX.Element => {
  const [currentStep, setCurrentStep] = useState<GoogleStep>(GoogleStep.START);
  const [googleAdsAccount, setGoogleAdsAccount] = useState<IAccount>({} as IAccount);
  const [userDetails, setUserDetails] = useState<IUserDetails>({} as IUserDetails);
  const [businessDetails, setBusinessDetails] = useState<IBusinessDetails>({} as IBusinessDetails);
  const [auth, setAuth] = useState<string | null>(null);
  const [isNewReport, setIsNewReport] = useState(false);

  const value: IGGContext = useMemo(
    () => ({
      currentStep,
      setCurrentStep,
      googleAdsAccount,
      setGoogleAdsAccount,
      userDetails,
      setUserDetails,
      businessDetails,
      setBusinessDetails,
      auth,
      setAuth,
      isNewReport,
      setIsNewReport,
    }),
    [currentStep, googleAdsAccount, userDetails, businessDetails, auth, isNewReport]
  );

  return <GoogleGraderContext.Provider value={value}>{children}</GoogleGraderContext.Provider>;
};

export const useGGContext = (): IGGContext => useContext(GoogleGraderContext);

export const withGGProvider = (component: JSX.Element, adType = GoogleAdType.NONE): JSX.Element => (
  <GoogleGraderContextProvider>
    <DataServiceProvider service={ServiceType.GOOGLE}>
      <GoogleAdTypeProvider googleAdType={adType}>{component}</GoogleAdTypeProvider>
    </DataServiceProvider>
  </GoogleGraderContextProvider>
);
