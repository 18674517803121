import { ReactNode } from 'react';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { LyraTheme as theme } from 'src/components/theme/lyra/Theme';
import { useMediaQuery } from '@mui/material';
import { GradeUtils } from 'src/utils/GradeUtils';
import { ScoreName } from 'src/enums/ScoreName';

import FoundationalDesktop from 'src/assets/websitegrader/background/foundational-desktop.svg';
import IntermediateDesktop from 'src/assets/websitegrader/background/intermediate-desktop.svg';
import AdvancedDesktop from 'src/assets/websitegrader/background/advanced-desktop.svg';

import FoundationalMobile from 'src/assets/websitegrader/background/foundational-mobile.svg';
import IntermediateMobile from 'src/assets/websitegrader/background/intermediate-mobile.svg';
import AdvancedMobile from 'src/assets/websitegrader/background/advanced-mobile.svg';

const BackgroundImage = (score: number, isMobile: boolean) => {
  switch (GradeUtils.getScoreNameFromNumber(score)) {
    case ScoreName.FOUNDATIONAL:
      return isMobile ? FoundationalMobile : FoundationalDesktop;
    case ScoreName.INTERMEDIATE:
      return isMobile ? IntermediateMobile : IntermediateDesktop;
    case ScoreName.ADVANCED:
      return isMobile ? AdvancedMobile : AdvancedDesktop;
    default:
      return isMobile ? FoundationalMobile : FoundationalDesktop;
  }
};

export const HeaderBackground = ({ $score, children }: { $score: number; children: ReactNode }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <FlexBoxColumn
      sx={{
        alignItems: 'flex-start',
        p: isMobile ? 2 : 5,
        pb: isMobile ? 7 : 10,
        backgroundImage: `url(${BackgroundImage($score, isMobile)})`,
        justifyContent: 'space-between',
        backgroundSize: 'cover',
        gap: isMobile ? 7 : 10,
      }}
    >
      {children}
    </FlexBoxColumn>
  );
};
