import { Divider, Table, TableRow, TableCell, Typography, TableBody } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { FacebookAdsScoreSection } from 'src/enums/facebook/ScoreSection';
import { useFAGradeScore } from 'src/hooks/facebook/useFAGradeScore';
import AudienceTypesTable from '../table/audienceTypes/AudienceTypesTable';
import { AudienceTypeStat, withSymbol } from '../table/utils';
import { getScoreName, getScoreNameForCPC } from '../table/getScoreName';
import { YouVsCompetitorMobile } from '../table/YouVsCompetitorMobile';
import { BoldTypography } from 'src/components/common/styled/BoldTypography';
import { ServiceType } from 'src/enums/ServiceType';
import { OrionColors } from 'src/components/theme/orion/common/colors';
import { OrionTheme } from 'src/components/theme/orion/Theme';

export const AudienceTypesResults = (): JSX.Element => {
  const { grade, currencySymbol } = useFAGradeScore({ sectionName: FacebookAdsScoreSection.AUDIENCE_TYPES });
  const { t } = useTranslation([ServiceType.FACEBOOK]);

  return (
    <FlexBoxColumn gap={5} sx={{ alignItems: 'flex-start', width: '100%' }}>
      <FlexBoxColumn gap={2} sx={{ width: '100%', alignItems: 'flex-start' }}>
        <BoldTypography variant="h5">{t('grade.audienceTypes.table.title')}</BoldTypography>
        <Typography variant="body1">{t('grade.audienceTypes.table.description')}</Typography>
      </FlexBoxColumn>
      <FlexBoxColumn gap={2} sx={{ width: '100%', alignItems: 'flex-start' }}>
        <FlexBoxColumn sx={{ alignSelf: 'stretch', alignItems: 'flex-start' }}>
          <BoldTypography variant="body2">{t('grade.audienceTypes.table.lookalikeCustomerList')}</BoldTypography>
          <AudienceTypesTable
            ctr={{
              you: grade?.audienceTypes.lookalikeCtr,
              competitors: grade?.audienceTypes.benchmarkLookalikeCtr,
              difference: grade?.audienceTypes.lookalikeCtrDiff,
            }}
            cpc={{
              you: grade?.audienceTypes.lookalikeCpc,
              competitors: grade?.audienceTypes.benchmarkLookalikeCpc,
              difference: grade?.audienceTypes.lookalikeCpcDiff,
              currencySymbol: currencySymbol,
            }}
          />
        </FlexBoxColumn>
        <Typography variant="body1">{t('grade.audienceTypes.table.lookalikeCustomerListDescription')}</Typography>
      </FlexBoxColumn>
      <FlexBoxColumn gap={2} sx={{ width: '100%', alignItems: 'flex-start' }}>
        <FlexBoxColumn sx={{ alignSelf: 'stretch', alignItems: 'flex-start' }}>
          <BoldTypography variant="h6">{t('grade.audienceTypes.table.retargeting')}</BoldTypography>
          <AudienceTypesTable
            ctr={{
              you: grade?.audienceTypes.retargetingCtr,
              competitors: grade?.audienceTypes.benchmarkRetargetingCtr,
              difference: grade?.audienceTypes.retargetingCtrDiff,
            }}
            cpc={{
              you: grade?.audienceTypes.retargetingCpc,
              competitors: grade?.audienceTypes.benchmarkRetargetingCpc,
              difference: grade?.audienceTypes.retargetingCpcDiff,
              currencySymbol: currencySymbol,
            }}
          />
        </FlexBoxColumn>
        <Typography variant="h6">{t('grade.audienceTypes.table.retargetingDescription')}</Typography>
      </FlexBoxColumn>
      <FlexBoxColumn gap={2} sx={{ width: '100%', alignItems: 'flex-start' }}>
        <FlexBoxColumn sx={{ alignSelf: 'stretch', alignItems: 'flex-start' }}>
          <BoldTypography variant="h6">{t('grade.audienceTypes.table.lookalikeWebVisitors')}</BoldTypography>
          <AudienceTypesTable
            ctr={{
              you: grade?.audienceTypes.webCtr,
              competitors: grade?.audienceTypes.benchmarkWebCtr,
              difference: grade?.audienceTypes.webCtrDiff,
            }}
            cpc={{
              you: grade?.audienceTypes.webCpc,
              competitors: grade?.audienceTypes.benchmarkWebCpc,
              difference: grade?.audienceTypes.webCpcDiff,
              currencySymbol: currencySymbol,
            }}
          />
        </FlexBoxColumn>
        <Typography variant="h6">{t('grade.audienceTypes.table.lookalikeWebVisitorsDescription')}</Typography>
      </FlexBoxColumn>
      <FlexBoxColumn gap={2} sx={{ width: '100%', alignItems: 'flex-start' }}>
        <FlexBoxColumn sx={{ alignSelf: 'stretch', alignItems: 'flex-start' }}>
          <BoldTypography variant="h6">{t('grade.audienceTypes.table.demographicTargeting')}</BoldTypography>
          <AudienceTypesTable
            ctr={{
              you: grade?.audienceTypes.demographicCtr,
              competitors: grade?.audienceTypes.benchmarkDemographicCtr,
              difference: grade?.audienceTypes.demographicCtrDiff,
            }}
            cpc={{
              you: grade?.audienceTypes.demographicCpc,
              competitors: grade?.audienceTypes.benchmarkDemographicCpc,
              difference: grade?.audienceTypes.demographicCpcDiff,
              currencySymbol: currencySymbol,
            }}
          />
        </FlexBoxColumn>
        <Typography variant="h6">{t('grade.audienceTypes.table.demographicTargetingDescription')}</Typography>
      </FlexBoxColumn>
    </FlexBoxColumn>
  );
};

export const AudienceTypesResultsMobile = (): JSX.Element => {
  const { grade, currencySymbol } = useFAGradeScore({ sectionName: FacebookAdsScoreSection.AUDIENCE_TYPES });
  const { t } = useTranslation([ServiceType.FACEBOOK]);

  return (
    <FlexBoxColumn sx={{ width: '100%', alignItems: 'flex-start' }} gap={1}>
      <FlexBoxColumn gap={2} sx={{ width: '100%', alignItems: 'flex-start', pb: 2 }}>
        <BoldTypography textAlign={'center'} variant="body1">
          {t('grade.audienceTypes.table.title')}
        </BoldTypography>
        <Typography variant="body1" textAlign={'left'}>
          {t('grade.audienceTypes.table.description')}
        </Typography>
      </FlexBoxColumn>
      {/* Lookalike of Customer List section starts here */}
      <Table
        sx={{
          width: '100%',
          tableLayout: 'fixed',
          borderSpacing: `0 ${OrionTheme.spacing(1)}`,
          borderCollapse: 'separate',
        }}
      >
        <TableBody>
          <TableRow>
            <TableCell colSpan={2} padding="none" sx={{ px: 1, fontWeight: 600 }}>
              {t('grade.audienceTypes.table.lookalikeCustomerList')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell size="small" colSpan={2}>
              <Divider sx={{ width: '100%', borderColor: OrionColors.gray.neutral }} />
            </TableCell>
          </TableRow>
          <YouVsCompetitorMobile
            title={AudienceTypeStat.CTR}
            you={withSymbol(grade?.audienceTypes.lookalikeCtr, '%', 'Not Used')}
            competitors={withSymbol(grade?.audienceTypes.benchmarkLookalikeCtr, '%')}
            withCompetitorTooltip={true}
            scorename={getScoreName({
              you: grade?.audienceTypes.lookalikeCtr,
              benchmark: grade?.audienceTypes.benchmarkLookalikeCtr,
              difference: grade?.audienceTypes.lookalikeCtrDiff,
              youZeroNotUsed: true,
            })}
          />
          <TableRow>
            <TableCell size="small" colSpan={2}>
              <Divider sx={{ width: '100%', borderColor: OrionColors.gray.neutral }} />
            </TableCell>
          </TableRow>
          <YouVsCompetitorMobile
            title={AudienceTypeStat.CPC}
            you={withSymbol(grade?.audienceTypes.lookalikeCpc, currencySymbol, 'Not Used')}
            competitors={withSymbol(grade?.audienceTypes.benchmarkLookalikeCpc, currencySymbol)}
            withCompetitorTooltip={true}
            scorename={getScoreNameForCPC({
              you: grade?.audienceTypes.lookalikeCpc,
              benchmark: grade?.audienceTypes.benchmarkLookalikeCpc,
              difference: grade?.audienceTypes.lookalikeCpcDiff,
              youZeroNotUsed: true,
            })}
          />
          <TableRow>
            <TableCell size="small" colSpan={2}>
              <Divider sx={{ width: '100%', borderColor: OrionColors.gray.neutral }} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell size="small" colSpan={2}>
              <Typography variant="body1">{t('grade.audienceTypes.table.lookalikeCustomerListDescription')}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {/* Lookalike of Customer List section ends here */}
      {/* Retargeting section starts here */}
      <Table
        sx={{
          width: '100%',
          tableLayout: 'fixed',
          borderSpacing: `0 ${OrionTheme.spacing(1)}`,
          borderCollapse: 'separate',
        }}
      >
        <TableBody>
          <TableRow>
            <TableCell colSpan={2} padding="none" sx={{ px: 1, fontWeight: 600 }}>
              {t('grade.audienceTypes.table.retargeting')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell size="small" colSpan={2}>
              <Divider sx={{ width: '100%', borderColor: OrionColors.gray.neutral }} />
            </TableCell>
          </TableRow>
          <YouVsCompetitorMobile
            title={AudienceTypeStat.CTR}
            you={withSymbol(grade?.audienceTypes.retargetingCtr, '%', 'Not Used')}
            competitors={withSymbol(grade?.audienceTypes.benchmarkRetargetingCtr, '%')}
            withCompetitorTooltip={true}
            scorename={getScoreName({
              you: grade?.audienceTypes.retargetingCtr,
              benchmark: grade?.audienceTypes.benchmarkRetargetingCtr,
              difference: grade?.audienceTypes.retargetingCtrDiff,
              youZeroNotUsed: true,
            })}
          />
          <TableRow>
            <TableCell size="small" colSpan={2}>
              <Divider sx={{ width: '100%', borderColor: OrionColors.gray.neutral }} />
            </TableCell>
          </TableRow>
          <YouVsCompetitorMobile
            title={AudienceTypeStat.CPC}
            you={withSymbol(grade?.audienceTypes.retargetingCpc, currencySymbol, 'Not Used')}
            competitors={withSymbol(grade?.audienceTypes.benchmarkRetargetingCpc, currencySymbol)}
            withCompetitorTooltip={true}
            scorename={getScoreNameForCPC({
              you: grade?.audienceTypes.retargetingCpc,
              benchmark: grade?.audienceTypes.benchmarkRetargetingCpc,
              difference: grade?.audienceTypes.retargetingCpcDiff,
              youZeroNotUsed: true,
            })}
          />
          <TableRow>
            <TableCell size="small" colSpan={2}>
              <Divider sx={{ width: '100%', borderColor: OrionColors.gray.neutral }} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell size="small" colSpan={2}>
              <Typography variant="h6">{t('grade.audienceTypes.table.retargetingDescription')}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {/* Retargeting section end here */}
      {/* Lookalike web Visitors secion starts here */}
      <Table
        sx={{
          width: '100%',
          tableLayout: 'fixed',
          borderSpacing: `0 ${OrionTheme.spacing(1)}`,
          borderCollapse: 'separate',
        }}
      >
        <TableBody>
          <TableRow>
            <TableCell colSpan={2} padding="none" sx={{ px: 1, fontWeight: 600 }}>
              {t('grade.audienceTypes.table.lookalikeWebVisitors')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell size="small" colSpan={2}>
              <Divider sx={{ width: '100%', borderColor: OrionColors.gray.neutral }} />
            </TableCell>
          </TableRow>
          <YouVsCompetitorMobile
            title={AudienceTypeStat.CTR}
            you={withSymbol(grade?.audienceTypes.webCtr, '%', 'Not Used')}
            competitors={withSymbol(grade?.audienceTypes.benchmarkWebCtr, '%')}
            withCompetitorTooltip={true}
            scorename={getScoreName({
              you: grade?.audienceTypes.webCtr,
              benchmark: grade?.audienceTypes.benchmarkWebCtr,
              difference: grade?.audienceTypes.webCtrDiff,
              youZeroNotUsed: true,
            })}
          />
          <TableRow>
            <TableCell size="small" colSpan={2}>
              <Divider sx={{ width: '100%', borderColor: OrionColors.gray.neutral }} />
            </TableCell>
          </TableRow>
          <YouVsCompetitorMobile
            title={AudienceTypeStat.CPC}
            you={withSymbol(grade?.audienceTypes.webCpc, currencySymbol, 'Not Used')}
            competitors={withSymbol(grade?.audienceTypes.benchmarkWebCpc, currencySymbol)}
            withCompetitorTooltip={true}
            scorename={getScoreNameForCPC({
              you: grade?.audienceTypes.webCpc,
              benchmark: grade?.audienceTypes.benchmarkWebCpc,
              difference: grade?.audienceTypes.webCpcDiff,
              youZeroNotUsed: true,
            })}
          />
          <TableRow>
            <TableCell size="small" colSpan={2}>
              <Divider sx={{ width: '100%', borderColor: OrionColors.gray.neutral }} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell size="small" colSpan={2}>
              <Typography variant="h6">{t('grade.audienceTypes.table.lookalikeWebVisitorsDescription')}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {/* Lookalike web Visitors secion ends here */}
      {/* Demographic interest targeting starts here */}
      <Table
        sx={{
          width: '100%',
          tableLayout: 'fixed',
          borderSpacing: `0 ${OrionTheme.spacing(1)}`,
          borderCollapse: 'separate',
        }}
      >
        <TableBody>
          <TableRow>
            <TableCell colSpan={2} padding="none" sx={{ px: 1, fontWeight: 600 }}>
              {t('grade.audienceTypes.table.demographicTargeting')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell size="small" colSpan={2}>
              <Divider sx={{ width: '100%', borderColor: OrionColors.gray.neutral }} />
            </TableCell>
          </TableRow>
          <YouVsCompetitorMobile
            title={AudienceTypeStat.CTR}
            you={withSymbol(grade?.audienceTypes.demographicCtr, '%', 'Not Used')}
            competitors={withSymbol(grade?.audienceTypes.benchmarkDemographicCtr, '%')}
            withCompetitorTooltip={true}
            scorename={getScoreName({
              you: grade?.audienceTypes.demographicCtr,
              benchmark: grade?.audienceTypes.benchmarkDemographicCtr,
              difference: grade?.audienceTypes.demographicCtrDiff,
              youZeroNotUsed: true,
            })}
          />
          <TableRow>
            <TableCell size="small" colSpan={2}>
              <Divider sx={{ width: '100%', borderColor: OrionColors.gray.neutral }} />
            </TableCell>
          </TableRow>
          <YouVsCompetitorMobile
            title={AudienceTypeStat.CPC}
            you={withSymbol(grade?.audienceTypes.demographicCpc, currencySymbol, 'Not Used')}
            competitors={withSymbol(grade?.audienceTypes.benchmarkDemographicCpc, currencySymbol)}
            withCompetitorTooltip={true}
            scorename={getScoreNameForCPC({
              you: grade?.audienceTypes.demographicCpc,
              benchmark: grade?.audienceTypes.benchmarkDemographicCpc,
              difference: grade?.audienceTypes.demographicCpcDiff,
              youZeroNotUsed: true,
            })}
          />
          <TableRow>
            <TableCell size="small" colSpan={2}>
              <Divider sx={{ width: '100%', borderColor: OrionColors.gray.neutral }} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell size="small" colSpan={2}>
              <Typography variant="h6">{t('grade.audienceTypes.table.demographicTargetingDescription')}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {/* Demographic interest targeting ends here */}
    </FlexBoxColumn>
  );
};
