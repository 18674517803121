import { BoxProps, Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { FlexBoxRow } from 'src/components/common/FlexBoxRow';
import { BoldTypography } from 'src/components/common/styled/BoldTypography';
import { GoogleAdsScoreSection } from 'src/enums/google/ScoreSection';
import { ScoreName } from 'src/enums/ScoreName';
import { useGGradeScore } from 'src/hooks/google/useGGradeScore';
import { IGoogleGrade } from 'src/types/google/GoogleGrade';
import { GradeUtils } from 'src/utils/GradeUtils';
import { ReactComponent as AlertIcon } from 'src/assets/AlertIconCurrent.svg';
import { ServiceType } from 'src/enums/ServiceType';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import styled from 'styled-components';

interface Props {
  sectionname: GoogleAdsScoreSection;
}

const Metric = ({ sectionname, grade }: Props & { grade: IGoogleGrade }) => {
  switch (sectionname) {
    case GoogleAdsScoreSection.WASTED_SPEND:
      return grade.negativeKeyword.wasteLocal.toLocaleString(process.env.REACT_APP_LOCALE, {
        minimumFractionDigits: 2,
        style: 'currency',
        currency: grade.currencyCode,
      });
    case GoogleAdsScoreSection.QUALITY_SCORE:
      return grade.qualityScore.duration90.weightedQualityScore.toLocaleString(process.env.REACT_APP_LOCALE, {
        maximumFractionDigits: 1,
        minimumFractionDigits: 1,
      });
    case GoogleAdsScoreSection.IMPRESSION_SHARE:
      return (grade.impressionShare.impressionShare / 100).toLocaleString(process.env.REACT_APP_LOCALE, {
        minimumFractionDigits: 0,
        style: 'percent',
      });
    case GoogleAdsScoreSection.ACCOUNT_ACTIVITY:
      return grade.accountActivity.duration30.accountStats.totalChanges.toLocaleString(process.env.REACT_APP_LOCALE, {
        minimumFractionDigits: 0,
      });
  }
};

interface IconProps {
  scorename: ScoreName | undefined;
  theme: Theme;
}

const StyledAlertIcon = styled(AlertIcon)<{ scorename: ScoreName | undefined; theme: Theme }>`
  fill: ${({ scorename, theme }: IconProps) => (scorename ? GradeUtils.getScoreColor(scorename, theme) : 'none')};
`;

const Text = (text: string) => (
  <Typography variant="overline" textTransform={'uppercase'} sx={{ fontWeight: 'fontWeightMedium' }}>
    {text}
  </Typography>
);

const Duration = ({ sectionname }: Props): JSX.Element => {
  switch (sectionname) {
    case GoogleAdsScoreSection.WASTED_SPEND:
      return Text('Next 12 Months');
    case GoogleAdsScoreSection.ACCOUNT_ACTIVITY:
      return Text('Last 30 Days');
    default:
      return <></>;
  }
};

interface ContentProps {
  title: string;
  scorename: ScoreName | undefined;
  sectionname: GoogleAdsScoreSection;
  metric: string;
}

const Content = ({ title, scorename, sectionname, metric }: ContentProps): JSX.Element => {
  const sx: BoxProps['sx'] = {
    whiteSpace: sectionname === GoogleAdsScoreSection.WASTED_SPEND ? 'pre' : 'normal',
    width: sectionname !== GoogleAdsScoreSection.WASTED_SPEND ? OrionTheme.spacing(36) : 'auto',
  };

  return (
    <FlexBoxColumn gap={2}>
      <FlexBoxColumn gap={0.5} sx={{ ...sx }}>
        <BoldTypography textAlign={'center'} variant="h4">
          {title}
        </BoldTypography>
        {Duration({ sectionname })}
      </FlexBoxColumn>
      <FlexBoxRow gap={1}>
        {scorename !== ScoreName.ADVANCED && <StyledAlertIcon scorename={scorename} width={40} height={40} />}
        <Typography variant="h3" whiteSpace={'pre'}>
          {metric}
        </Typography>
      </FlexBoxRow>
    </FlexBoxColumn>
  );
};

export const SearchKeyMetric = ({ sectionname, sectionKey }: Props & { sectionKey: string }): JSX.Element => {
  const { searchGrade, scoreName } = useGGradeScore({ sectionName: sectionname });
  const metric = searchGrade && Metric({ sectionname, grade: searchGrade });
  const { t } = useTranslation([ServiceType.GOOGLE]);

  return searchGrade ? (
    Content({
      title: t('grade.keyMetricScoreTitle', { context: sectionKey }),
      scorename: scoreName,
      sectionname,
      metric: metric ?? '-',
    })
  ) : (
    <></>
  );
};
