import { GoogleAdsPage } from 'src/components/googleAds/page/Page';
import { GridType } from 'src/enums/GridType';
import { TFunction, useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { PageTitle } from 'src/enums/PageTitle';
import { ServiceType } from 'src/enums/ServiceType';
import { MetricsAccordions, MetricsTabs } from 'src/components/displayAds/metrics/Metrics';
import { SectionContent, GoogleDisplayTabs } from 'src/components/displayAds/Grades';
import { useReportLinksData } from 'src/hooks/common/useReportLinksData';
import { Heading } from '../googleAds/Report';
import { ReportLink } from 'src/components/common/ReportLink';
import { useReport } from './useReport';
import { GoogleAdsNoSpend } from '../googleAds/NoSpend';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { GradeAccordions as GoogleDisplayAccordions } from 'src/components/common/grade/Accordions';
import { GoogleDisplayScoreSection } from 'src/enums/google/ScoreSection';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import BadgeFooter from '../../components/common/BadgeFooter';
import GooglePartnerSVG from '../../assets/GooglePartner.svg';
import { useMediaQuery } from '@mui/material';

interface ContentProps {
  t: TFunction;
}

const Content = ({ t }: ContentProps): JSX.Element => {
  const reportlinksData = useReportLinksData();
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down('sm'));

  return isMobile ? (
    <>
      <GoogleDisplayAccordions
        defaultExpandedSection={GoogleDisplayScoreSection.STATUS}
        scoreSections={Object.values(GoogleDisplayScoreSection)}
        renderSectionContent={(sectionName) => (
          <SectionContent sectionName={sectionName as GoogleDisplayScoreSection} isMobile={true} />
        )}
      />
      <Heading whiteSpace="pre" text={t('metrics.deepDive')} isMobile={isMobile} />
      <MetricsAccordions />
      <Heading whiteSpace="pre" text={t('resultsByProduct')} isMobile={isMobile} />
      <FlexBoxColumn sx={{ gap: 2 }}>
        {reportlinksData.map((reportLinkData) => (
          <ReportLink
            adType={reportLinkData.adtype}
            url={reportLinkData.url}
            isMobile={isMobile}
            key={reportLinkData.pathName}
            scorename={reportLinkData.scoreName}
            scorevalue={reportLinkData.scoreValue}
          />
        ))}
      </FlexBoxColumn>
    </>
  ) : (
    <>
      <GoogleDisplayTabs />
      <Heading whiteSpace="normal" text={t('metrics.deepDive')} isMobile={isMobile} />
      <MetricsTabs />
      <Heading whiteSpace="normal" text={t('resultsByProduct')} isMobile={isMobile} />
      <FlexBoxColumn sx={{ gap: 2 }}>
        {reportlinksData.map((reportLinkData) => (
          <ReportLink
            adType={reportLinkData.adtype}
            url={reportLinkData.url}
            isMobile={isMobile}
            key={reportLinkData.pathName}
            scorename={reportLinkData.scoreName}
            scorevalue={reportLinkData.scoreValue}
          />
        ))}
      </FlexBoxColumn>
    </>
  );
};

export const DisplayReport = (): JSX.Element => {
  const { t } = useTranslation([ServiceType.GOOGLE]);
  const { isZeroDollarsSpent } = useReport();

  return (
    <>
      <Helmet>
        <title>
          {PageTitle.DISPLAY} | {'Report'}
        </title>
      </Helmet>
      {isZeroDollarsSpent && <GoogleAdsNoSpend />}
      {!isZeroDollarsSpent && (
        <GoogleAdsPage
          gridType={GridType.TAB}
          additionalFooter={
            <BadgeFooter
              i18nNameSpace={ServiceType.GOOGLE}
              badgeSVG={GooglePartnerSVG}
              iconHref={t('googleBadgeLink')}
            />
          }
        >
          <Content t={t} />
        </GoogleAdsPage>
      )}
    </>
  );
};
